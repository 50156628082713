import React, { useState, useEffect } from "react";
import { GetUserEntry, PostProduct } from "../../service/ratio/RatioService";
import Swal from "sweetalert2";
import Full from "../../load/Loading";
import "../../App.css";
import numeral from "numeral";

function ModalEntry({
  onCloseModal,
  Entry_Id,
  Entry_Name,
  Target_Estimasi,
  rank,
  teamId,
  setModalIsOpenUser,
}) {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const onCloseModalProperty = () => {
    onCloseModal();
    window.location.reload();
  };

  const [dataUserEntry, setDataUserEntry] = useState([]);
  const [productData, setProductData] = useState([]);

  const handleInputChange = (userId, field, value) => {
    const numericValue = value.replace(/\D/g, "");
    setProductData((prev) => {
      const index = prev.findIndex((item) => item.user_id === userId);
      if (index !== -1) {
        const updatedArray = [...prev];
        updatedArray[index][field] = numericValue;
        return updatedArray;
      } else {
        return [...prev, { user_id: userId, [field]: numericValue }];
      }
    });
  };

  useEffect(() => {
    dataGet();
  }, [Entry_Id]);

  const dataGet = () => {
    showLoader();
    const payload = {
      rank: rank,
      team_id: teamId,
      product_list_id: Entry_Id,
    };
    GetUserEntry(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataUserEntry(res.data.data);
          initializeDataTables();
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  };
  const handleSubmit = (event) => {
    showLoader();
    event.preventDefault();
    const payload = {
      rank: rank,
      team_id: teamId,
      product_list_id: Entry_Id,
      list_product: productData,
    };
    PostProduct(payload)
      .then((res) => {
        if (res.data.success == true) {
          Swal.fire({
            title: res.data.message,
            showConfirmButton: false,
            icon: "success",
          });
          hideLoader();
          dataGet();
          setModalIsOpenUser(true);
        } else {
          Swal.fire({
            title: res.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
          window.location.reload();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
        Swal.fire({
          title: err.response.data.message,
          showConfirmButton: false,
          icon: "warning",
        });
      });
    hideLoader();
  };

  const formatToRupiah = (value) => {
    return numeral(value).format("0,0"); // Format angka dengan koma sebagai pemisah ribuan
  };

  const initializeDataTables = () => {
    // Initialize DataTables library here after data is fetched and rendered
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/content.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  const calculateInBox = (userId) => {
    const userData = productData.find((item) => item.user_id === userId) || {};
    const numberR = parseInt(userData.number_R, 10) || 1;
    const unitPerR = parseInt(userData.unit_per_R, 10) || 1;
    const workingDay = parseInt(userData.working_day, 10) || 1;

    // Kalkulasi estimasi berdasarkan inputan
    return numberR * unitPerR * workingDay;
  };

  const calculateInUnit = (userId) => {
    const userData = productData.find((item) => item.user_id === userId) || {};
    const numberR = parseInt(userData.number_R, 10) || 0;
    const unitPerR = parseInt(userData.unit_per_R, 10) || 1;
    const workingDay = parseInt(userData.working_day, 10) || 1;

    // Kalkulasi estimasi berdasarkan inputan
    return numberR * unitPerR * workingDay;
  };

  const calculateEstimation = (userId) => {
    const userData = productData.find((item) => item.user_id === userId) || {};
    const numberR = parseInt(userData.number_R, 10) || 0;
    const unitPerR = parseInt(userData.unit_per_R, 10) || 1;
    const workingDay = parseInt(userData.working_day, 10) || 1;

    // Kalkulasi estimasi berdasarkan inputan
    return numberR * unitPerR * workingDay;
  };

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-60 z-30 max-h-[100vh]"></div>

      <div
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-40 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className=" pb-12 mx-[3%] my-[2%] max-w-8xl">
          {/* Modal content */}
          <div className="bg-white rounded-lg shadow">
            {/* Modal header */}
            <div className="flex items-center justify-between p-1 md:p-5 border-b rounded-t dark:border-gray-600">
              <button
                onClick={onCloseModalProperty}
                type="button"
                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xl w-12 h-12 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="px-3 overflow-y-auto overflow-x-auto max-h-[800px]">
              <div className="p-2 md:p-5 relative ">
                <h5 className="font-semibold text-[16px] mb-3">
                  Pilih produk - produk untuk ditargetkan !
                </h5>
                <ul className="list-disc pl-5 text-[14px] font-extralight mb-1">
                  <li>
                    Silakan pilih user, kemudian mengisi target jumlah R, jumlah
                    Rx, dan Hari Kerja (HK).
                  </li>
                  <li>
                    Jika sudah jangan lupa untuk klik tombol{" "}
                    <span className="font-bold">KALKULASI</span> .
                  </li>
                </ul>
                <div className="flex flex-col lg:flex-row md:flex-row justify-between mb-1">
                  <div className="w-full lg:w-[500px] md:w-1/2 lg:my-6 my-2">
                    <h3 className="font-medium text-[16px]">
                      Product : {Entry_Name}
                    </h3>
                  </div>

                  <div className="w-full lg:w-1/3 lg:my-6 md:w-1/2 my-2 flex justify-end">
                    <h3 className="font-medium text-[16px]">
                      Target Estimasi : Rp.{formatToRupiah(Target_Estimasi)}
                    </h3>
                  </div>
                </div>
                <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                  <div>
                    <form className="space-y-4" onSubmit={handleSubmit}>
                      <div className="card-body">
                        <table
                          className="table table-bordered table-hover table-scroll"
                          id="example401"
                          style={{
                            fontSize: "12px",
                            fontWeight: "semiBold",
                            display: "block",
                            overflow: "scroll",
                            height: "420px",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <thead
                            style={{
                              backgroundColor: "#736098",
                              color: "white",
                              textAlign: "center",
                              top: "0",
                              zIndex: "10",
                              position: "sticky",
                              fontWeight: "reguler",
                            }}
                          >
                            <tr className="text-center">
                              <th
                                rowSpan={"2"}
                                width="1%"
                                style={{
                                  left: "0",
                                  zIndex: "5",
                                  position: "sticky",
                                  verticalAlign: "middle",
                                  backgroundColor: "#736098",
                                }}
                              >
                                NO
                              </th>
                              <th
                                rowSpan={"2"}
                                width="20%"
                                style={{
                                  left: "62px",
                                  zIndex: "10",
                                  position: "sticky",
                                  verticalAlign: "middle",
                                  backgroundColor: "#736098",
                                }}
                              >
                                USER
                              </th>
                              <th
                                rowSpan={"2"}
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                R <br></br>(Resep per Hari)
                              </th>
                              <th
                                rowSpan={"2"}
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                Rx <br></br>(Jumlah Unit per Resep)
                              </th>
                              <th
                                rowSpan={"2"}
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                HK
                              </th>
                              <th
                                colSpan={"3"}
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                ESTIMASI
                              </th>
                              <th colSpan={"2"}>TIMESTAMP</th>
                            </tr>
                            <tr className="text-center">
                              <th>IN BOX</th>
                              <th>IN UNIT</th>
                              <th>VALUE</th>
                              <th>INPUT</th>
                              <th>UPDATE</th>
                            </tr>
                          </thead>

                          <tbody style={{ textAlign: "right" }}>
                            {dataUserEntry.list_user?.map((list, index) => {
                              const userInBox =
                                calculateInBox(list.user_id) /
                                list.quantity_per_box;

                              const userInUnit = calculateInUnit(list.user_id);

                              const userEstimation =
                                calculateEstimation(list.user_id) *
                                list.price_pcs;

                              const numberRValue = productData.find(
                                (item) => item.user_id === list.user_id
                              )?.number_R;

                              const valueR =
                                list.value_R !== "" ? list.value_R : "";

                              const PerRValue = productData.find(
                                (item) => item.user_id === list.user_id
                              )?.unit_per_R;

                              const valueRX =
                                list.value_RX !== "" ? list.value_RX : "";

                              const HkValue = productData.find(
                                (item) => item.user_id === list.user_id
                              )?.working_day;

                              const valueHK =
                                list.value_HK !== "" ? list.value_HK : "";
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                  }}
                                >
                                  <td
                                    width={"1%"}
                                    style={{
                                      textAlign: "center",
                                      left: "0px",
                                      zIndex: "5",
                                      position: "sticky",
                                      backgroundColor: "#ffffff",
                                    }}
                                  >
                                    {index + 1}.
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      left: "62px",
                                      zIndex: "5",
                                      position: "sticky",
                                      backgroundColor: "#ffffff",
                                    }}
                                  >
                                    {list.user_name}
                                  </td>
                                  <td width={"10%"}>
                                    <input
                                      type="text"
                                      maxLength={4}
                                      value={
                                        numberRValue !== undefined
                                          ? numberRValue
                                          : valueR
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          list.user_id,
                                          "number_R",
                                          e.target.value
                                        )
                                      }
                                      className={`p-1 w-full border-1 rounded-lg max-w-60 text-sm `}
                                    />
                                  </td>
                                  <td width={"10%"}>
                                    <input
                                      type="text"
                                      maxLength={4}
                                      value={
                                        PerRValue !== undefined
                                          ? PerRValue
                                          : valueRX
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          list.user_id,
                                          "unit_per_R",
                                          e.target.value
                                        )
                                      }
                                      className={`p-1 w-full border-1 rounded-lg max-w-60 text-sm `}
                                    />
                                  </td>
                                  <td width={"10%"}>
                                    <input
                                      type="text"
                                      maxLength={2}
                                      value={
                                        HkValue !== undefined
                                          ? HkValue
                                          : valueHK
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          list.user_id,
                                          "working_day",
                                          e.target.value
                                        )
                                      }
                                      className={`p-1 w-full border-1  rounded-lg max-w-60 text-sm `}
                                    />
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {/* {formatToRupiah(
                                      userInBox || list.user_estimasi_box
                                    )} */}
                                    {list.user_estimasi_box}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {formatToRupiah(
                                      userInUnit || list.user_estimasi_unit
                                    )}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {formatToRupiah(
                                      userEstimation || list.user_estimasi_value
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {list.input_date !== 0 ? (
                                      <span>{list.input_date}</span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </td>
                                  <td>
                                    {list.update_date !== 0 ? (
                                      <span>{list.update_date}</span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="card-body" style={{ marginTop: "-45px" }}>
                        <table className="table">
                          <thead></thead>
                          <tbody
                            style={{
                              textAlign: "center",
                              fontWeight: "reguler",
                              fontSize: "14px",
                            }}
                          >
                            <tr>
                              <th
                                rowSpan={2}
                                style={{
                                  textAlign: "center", // Horizontal centering
                                  verticalAlign: "middle", // Vertical centering
                                }}
                              >
                                TOTAL ESTIMASI VALUE
                              </th>
                              <th
                                style={{
                                  textAlign: "center", // Horizontal centering
                                  verticalAlign: "middle", // Vertical centering
                                }}
                              >
                                {dataUserEntry.subtotal_user_estimasi <
                                Target_Estimasi ? (
                                  <span className="text-center font-normal text-redBrave">
                                    Rp.{" "}
                                    {formatToRupiah(
                                      dataUserEntry.subtotal_user_estimasi
                                    )}
                                  </span>
                                ) : (
                                  <span>
                                    Rp.{" "}
                                    {formatToRupiah(
                                      dataUserEntry.subtotal_user_estimasi
                                    )}
                                  </span>
                                )}
                              </th>

                              <th
                                style={{
                                  textAlign: "center", // Horizontal centering
                                  verticalAlign: "middle", // Vertical centering
                                }}
                              >
                                {dataUserEntry.subtotal_user_estimasi <
                                Target_Estimasi ? (
                                  <span className="text-center font-normal text-redBrave">
                                    (Masih kurang dari Estimasi Target)
                                  </span>
                                ) : null}
                              </th>

                              <th width={"10%"}>
                                <button
                                  className={`w-full lg:w-full   rounded-lg border-none border-redBrave bg-redBrave`}
                                >
                                  <h5 className="text-center font-normal p-2 text-white">
                                    Kalkulasi
                                  </h5>
                                </button>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
}

export default ModalEntry;
