import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  getDataRatioDetail,
  getDataRatioSubDetail,
} from "../../service/ratio/RatioService";
import Full from "../../load/Loading";
import Swal from "sweetalert2";
import Highcharts from "highcharts";

function MainDashboard({
  data1,
  tipe,
  ratioSTO,
  ratioBDU,
  Mark,
  nowDate,
  month,
  color,
  regionalData,
  dataArrays,
}) {
  const id_type = localStorage.getItem("id_type");
  const is_manager = localStorage.getItem("is_manager");
  //loading
  const [loader, showLoader, hideLoader] = Full();
  const params = useParams();
  //setting tiperatio
  const targetRef2 = useRef(null);
  const targetRef3 = useRef(null);
  const targetRef4 = useRef(null);
  const targetRef5 = useRef(null);

  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [name3, setName3] = useState("");
  const [name4, setName4] = useState("");

  //show piechart
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);

  const [ratioDetail2, setDataRatioDetail2] = useState({});
  const [ratioDetail3, setDataRatioDetail3] = useState({});
  const [ratioDetail4, setDataRatioDetail4] = useState({});
  const [ratioDetail, setDataRatioSubDetail] = useState([]);

  const data2 = Object.values(ratioDetail2).map((region) => region.data[0]);
  const data3 = Object.values(ratioDetail3).map((region) => region.data[0]);
  const data4 = Object.values(ratioDetail4).map((region) => region.data[0]);

  const dataArray = Object.values(ratioDetail);

  const pctArray = dataArray.map((item) => ({
    name: item.name,
    y: item.pct !== 0 ? item.pct : null,
    user_transaksi: item.user_transaksi,
  }));

  //klik untuk turunan kedua

  const handleSliceClick2 = (ranks, type, team_1, name) => {
    if (targetRef2.current) {
      targetRef2.current.scrollIntoView({ behavior: "smooth" });
    }
    setName1(name);
    setShow3(false);
    setShow4(false);
    setShow5(false);
    const payload = {
      rank: ranks ? ranks : params.id,
      type: type,
      team_id_01: team_1 ? team_1 : "",
      team_id_02: "",
      team_id_03: "",
      team_id_04: "",
      team_id: "",
      id_type: "",
      periode: month ? month : "",
    };
    showLoader();
    getDataRatioDetail(payload)
      .then((res) => {
        if (res.data.data["BDU"] == "0" || res.data.data["STO"] == "0") {
          Swal.fire({
            title: "Belum Ada Data Saat ini",
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        } else {
          setDataRatioDetail2(res.data.data);
          setShow2(true);

          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  //klik untuk turunan ketiga
  const handleSliceClick3 = (ranks, type, team_1, team_2, name) => {
    if (targetRef3.current) {
      targetRef3.current.scrollIntoView({ behavior: "smooth" });
    }

    setName2(name);
    setShow4(false);
    setShow5(false);
    const payload = {
      rank: ranks ? ranks : params.id,
      type: type,
      team_id_01: team_1 ? team_1 : "",
      team_id_02: team_2 ? team_2 : "",
      team_id_03: "",
      team_id_04: "",
      team_id: "",
      id_type: "",
      periode: month ? month : "",
    };
    showLoader();
    getDataRatioDetail(payload)
      .then((res) => {
        if (res.data.data["BDU"] == "0" || res.data.data["STO"] == "0") {
          Swal.fire({
            title: "Belum Ada Data Saat ini",
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        } else {
          setDataRatioDetail3(res.data.data);
          setShow3(true);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  //klik untuk turunan keempat
  const handleSliceClick4 = (ranks, type, team_1, team_2, team_3, name) => {
    if (targetRef4.current) {
      targetRef4.current.scrollIntoView({ behavior: "smooth" });
    }
    setName3(name);

    const payload = {
      rank: ranks ? ranks : params.id,
      type: type,
      team_id_01: team_1 ? team_1 : "",
      team_id_02: team_2 ? team_2 : "",
      team_id_03: team_3,
      team_id_04: "",
      team_id: "",
      id_type: "",
      periode: month ? month : "",
    };
    showLoader();
    getDataRatioDetail(payload)
      .then((res) => {
        if (res.data.data["BDU"] == "0" || res.data.data["STO"] == "0") {
          Swal.fire({
            title: "Belum Ada Data Saat ini",
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
        } else {
          setDataRatioDetail4(res.data.data);
          setShow4(true);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const handleSliceClick5 = (ranks, type, team_4, name) => {
    if (targetRef5.current) {
      targetRef5.current.scrollIntoView({ behavior: "smooth" });
    }
    setName4(name);
    setShow5(true);
    let payload;
    if (tipe == "BDU") {
      payload = {
        rank: ranks ? ranks : params.id,
        type: type,
        search_name: "detail_user",
        search_id: team_4,
        user_id: "",
        team_id: team_4,
        periode: month,
        id_type: "4",
      };
    } else if (tipe == "STO") {
      payload = {
        rank: ranks ? ranks : params.id,
        type: type,
        search_name: "detail_product",
        search_id: team_4,
        user_id: "",
        team_id: team_4,
        periode: month,
        id_type: "4",
      };
    }
    getDataRatioSubDetail(payload)
      .then((res) => {
        if (res.data.success == true) {
          if (tipe === "BDU") {
            setDataRatioSubDetail(res.data.data["BDU"]);
          } else if (tipe === "STO") {
            setDataRatioSubDetail(res.data.data["STO"]);
          }
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  useEffect(() => {
    if (tipe === "STO") {
      Highcharts.chart("container", {
        chart: {
          type: "column",
          scrollablePlotArea: {
            minWidth: 4000,
            scrollPositionY: 1,
          },
        },
        title: {
          text: ``,
          align: "center",
        },
        xAxis: {
          tickWidth: 1,
          categories: dataArrays,
          crosshair: true,
          labels: {
            overflow: "justify",
          },
        },
        yAxis: {
          min: 0,
        },
        tooltip: {
          valueSuffix: "",
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 0,
          },
        },
        series: regionalData,
      });
    }
  }, [regionalData]);

  // //showing piechart turunan 1
  useEffect(() => {
    setShow2(false);
    setShow3(false);
    setShow4(false);
    setShow5(false);
    if (tipe === "STO") {
      Highcharts.chart("container1", {
        chart: {
          type: "pie",
        },
        title: {
          text: "",
          align: "center",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          series: {
            borderRadius: 5,
            cursor: "pointer",
            dataLabels: [
              {
                enabled: true,
                distance: 8,
                format: "{point.name}",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                },
              },
              {
                enabled: true,
                distance: "-30%",
                filter: {
                  property: "percentage",
                  operator: ">",
                  value: 5,
                },
                format: "{point.y:.1f}%",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                  color: "#ffffff",
                },
              },
            ],
            // Handle event click pada setiap data
            point: {
              events: {
                click: function () {
                  if (this.name != null) {
                    if (id_type == "0") {
                      handleSliceClick2(
                        Mark,
                        ratioSTO,
                        this.drilldown_1,
                        this.name
                      );
                    } else if (id_type == "1") {
                      handleSliceClick3(
                        Mark,
                        ratioSTO,
                        this.drilldown_1,
                        this.drilldown_2,
                        this.name
                      );
                    } else if (id_type == "2") {
                      handleSliceClick4(
                        Mark,
                        ratioSTO,
                        this.drilldown_1,
                        this.drilldown_2,
                        this.drilldown_3,
                        this.name
                      );
                    } else if (id_type == "3") {
                      handleSliceClick5(
                        Mark,
                        ratioSTO,
                        this.drilldown_4,
                        this.name
                      );
                    }
                  }
                },
              },
            },
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.subtotal}</b> of total <b>{point.grandtotal}</b> Jumlah Outlet Transaksi <b>{point.outlet_transaksi}</b><br> Achievement <b>{point.target_pct} % ({point.subtotal} </b> of total <b>{point.target}</b>)</b><br/>',
        },

        series: [
          {
            data: data1,
          },
        ],
      });
    } else if (tipe === "BDU") {
      Highcharts.chart("container1", {
        chart: {
          type: "pie",
        },
        title: {
          text: "",
          align: "center",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          series: {
            borderRadius: 5,
            cursor: "pointer",
            dataLabels: [
              {
                enabled: true,
                distance: 8,
                format: "{point.name}",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                },
              },
              {
                enabled: true,
                distance: "-30%",
                filter: {
                  property: "percentage",
                  operator: ">",
                  value: 5,
                },
                format: "{point.y:.1f}%",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                  color: "#ffffff",
                },
              },
            ],
            // Handle event click pada setiap data
            point: {
              events: {
                click: function () {
                  if (this.name != null) {
                    if (id_type == "0") {
                      handleSliceClick2(
                        Mark,
                        ratioBDU,
                        this.drilldown_1,
                        this.name
                      );
                    } else if (id_type == "1") {
                      handleSliceClick3(
                        Mark,
                        ratioBDU,
                        this.drilldown_1,
                        this.drilldown_2,
                        this.name
                      );
                    } else if (id_type == "2") {
                      handleSliceClick4(
                        Mark,
                        ratioBDU,
                        this.drilldown_1,
                        this.drilldown_2,
                        this.drilldown_3,
                        this.name
                      );
                    } else if (id_type == "3") {
                      handleSliceClick5(
                        Mark,
                        ratioBDU,
                        this.drilldown_4,
                        this.name
                      );
                    }
                  }
                },
              },
            },
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.subtotal}</b> of total <b>{point.grandtotal}</b> Jumlah Outlet Transaksi <b>{point.outlet_transaksi}</b><br> Achievement <b>{point.target_pct} % ({point.subtotal} </b> of total <b>{point.target}</b>)</b><br/>',
        },

        series: [
          {
            data: data1,
          },
        ],
      });
    }
  }, [data1]);

  //showing piechart turunan 2
  useEffect(() => {
    if (tipe === "STO") {
      Highcharts.chart("container2", {
        chart: {
          type: "pie",
        },
        title: {
          text: "",
          align: "center",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          series: {
            borderRadius: 5,
            cursor: "pointer",
            dataLabels: [
              {
                enabled: true,
                distance: 8,
                format: "{point.name}",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                },
              },
              {
                enabled: true,
                distance: "-30%",
                filter: {
                  property: "percentage",
                  operator: ">",
                  value: 5,
                },
                format: "{point.y:.1f}%",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                  color: "#ffffff",
                },
              },
            ],
            // Handle event click pada setiap data
            point: {
              events: {
                click: function () {
                  if (this.name != null) {
                    if (this.drilldown_2 != null) {
                      handleSliceClick3(
                        Mark,
                        ratioSTO,
                        this.drilldown_1,
                        this.drilldown_2,
                        this.name
                      );
                    }
                  }
                },
              },
            },
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.subtotal}</b> of total <b>{point.grandtotal}</b> Jumlah Outlet Transaksi <b>{point.outlet_transaksi}</b><br> Achievement <b>{point.target_pct} % ({point.subtotal} </b> of total <b>{point.target}</b>)</b><br/>',
        },

        series: [
          {
            data: data2,
          },
        ],
      });
    } else if (tipe === "BDU") {
      Highcharts.chart("container2", {
        chart: {
          type: "pie",
        },
        title: {
          text: "",
          align: "center",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          series: {
            borderRadius: 5,
            cursor: "pointer",
            dataLabels: [
              {
                enabled: true,
                distance: 8,
                format: "{point.name}",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                },
              },
              {
                enabled: true,
                distance: "-30%",
                filter: {
                  property: "percentage",
                  operator: ">",
                  value: 5,
                },
                format: "{point.y:.1f}%",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                  color: "#ffffff",
                },
              },
            ],
            // Handle event click pada setiap data
            point: {
              events: {
                click: function () {
                  if (this.name != null) {
                    if (this.drilldown_2 != null) {
                      handleSliceClick3(
                        Mark,
                        ratioBDU,
                        this.drilldown_1,
                        this.drilldown_2,
                        this.name
                      );
                    }
                  }
                },
              },
            },
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.subtotal}</b> of total <b>{point.grandtotal}</b> Jumlah Outlet Transaksi <b>{point.outlet_transaksi}</b><br> Achievement <b>{point.target_pct} % ({point.subtotal} </b> of total <b>{point.target}</b>)</b><br/>',
        },

        series: [
          {
            data: data2,
          },
        ],
      });
    }
  }, [data2, name1]);

  useEffect(() => {
    if (tipe === "STO") {
      Highcharts.chart("container3", {
        chart: {
          type: "pie",
        },
        title: {
          text: "",
          align: "center",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          series: {
            borderRadius: 5,
            cursor: "pointer",
            dataLabels: [
              {
                enabled: true,
                distance: 8,
                format: "{point.name}",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                },
              },
              {
                enabled: true,
                distance: "-30%",
                filter: {
                  property: "percentage",
                  operator: ">",
                  value: 5,
                },
                format: "{point.y:.1f}%",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                  color: "#ffffff",
                },
              },
            ],
            // Handle event click pada setiap data
            point: {
              events: {
                click: function () {
                  if (this.name != null) {
                    if (this.drilldown_3 != null) {
                      handleSliceClick4(
                        Mark,
                        ratioSTO,
                        this.drilldown_1,
                        this.drilldown_2,
                        this.drilldown_3,
                        this.name
                      );
                    }
                  }
                },
              },
            },
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.subtotal}</b> of total <b>{point.grandtotal}</b> Jumlah Outlet Transaksi <b>{point.outlet_transaksi}</b><br> Achievement <b>{point.target_pct} % ({point.subtotal} </b> of total <b>{point.target}</b>)</b><br/>',
        },

        series: [
          {
            data: data3,
          },
        ],
      });
    } else if (tipe === "BDU") {
      Highcharts.chart("container3", {
        chart: {
          type: "pie",
        },
        title: {
          text: "",
          align: "center",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          series: {
            borderRadius: 5,
            cursor: "pointer",
            dataLabels: [
              {
                enabled: true,
                distance: 8,
                format: "{point.name}",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                },
              },
              {
                enabled: true,
                distance: "-30%",
                filter: {
                  property: "percentage",
                  operator: ">",
                  value: 5,
                },
                format: "{point.y:.1f}%",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                  color: "#ffffff",
                },
              },
            ],
            // Handle event click pada setiap data
            point: {
              events: {
                click: function () {
                  if (this.name != null) {
                    if (this.drilldown_3 != null) {
                      handleSliceClick4(
                        Mark,
                        ratioBDU,
                        this.drilldown_1,
                        this.drilldown_2,
                        this.drilldown_3,
                        this.name
                      );
                    }
                  }
                },
              },
            },
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.subtotal}</b> of total <b>{point.grandtotal}</b> Jumlah Outlet Transaksi <b>{point.outlet_transaksi}</b><br> Achievement <b>{point.target_pct} % ({point.subtotal} </b> of total <b>{point.target}</b>)</b><br/>',
        },

        series: [
          {
            data: data3,
          },
        ],
      });
    }
  }, [data3, name2]);

  //showing piechart turunan 4
  useEffect(() => {
    if (tipe === "STO") {
      Highcharts.chart("container4", {
        chart: {
          type: "pie",
        },
        title: {
          text: "",
          align: "center",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          series: {
            borderRadius: 5,
            cursor: "pointer",
            dataLabels: [
              {
                enabled: true,
                distance: 8,
                format: "{point.name}",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                },
              },
              {
                enabled: true,
                distance: "-30%",
                filter: {
                  property: "percentage",
                  operator: ">",
                  value: 5,
                },
                format: "{point.y:.1f}%",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                  color: "#ffffff",
                },
              },
            ],
            // Handle event click pada setiap data
            point: {
              events: {
                click: function () {
                  if (this.name != null) {
                    if (this.drilldown_4 != null) {
                      handleSliceClick5(
                        Mark,
                        ratioSTO,
                        this.drilldown_4,
                        this.name
                      );
                    }
                  }
                },
              },
            },
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.subtotal}</b> of total <b>{point.grandtotal}</b> Jumlah Outlet Transaksi <b>{point.outlet_transaksi}</b><br> Achievement <b>{point.target_pct} % ({point.subtotal} </b> of total <b>{point.target}</b>)</b><br/>',
        },
        series: [
          {
            data: data4,
          },
        ],
      });
    } else if (tipe === "BDU") {
      Highcharts.chart("container4", {
        chart: {
          type: "pie",
        },
        title: {
          text: "",
          align: "center",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          series: {
            borderRadius: 5,
            cursor: "pointer",
            dataLabels: [
              {
                enabled: true,
                distance: 8,
                format: "{point.name}",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                },
              },
              {
                enabled: true,
                distance: "-30%",
                filter: {
                  property: "percentage",
                  operator: ">",
                  value: 5,
                },
                format: "{point.y:.1f}%",
                style: {
                  fontSize: "12px",
                  textOutline: "none",
                  color: "#ffffff",
                },
              },
            ],
            // Handle event click pada setiap data
            point: {
              events: {
                click: function () {
                  if (this.name != null) {
                    if (this.drilldown_4 != null) {
                      handleSliceClick5(
                        Mark,
                        ratioBDU,
                        this.drilldown_4,
                        this.name
                      );
                    }
                  }
                },
              },
            },
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.subtotal}</b> of total <b>{point.grandtotal}</b> Jumlah Outlet Transaksi <b>{point.outlet_transaksi}</b><br> Achievement <b>{point.target_pct} % ({point.subtotal} </b> of total <b>{point.target}</b>)</b><br/>',
        },

        series: [
          {
            data: data4,
          },
        ],
      });
    }
  }, [data4, name3]);

  useEffect(() => {
    Highcharts.chart("container5", {
      chart: {
        type: "pie",
      },
      title: {
        text: "",
        align: "center",
      },

      accessibility: {
        announceNewData: {
          enabled: false,
        },
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: [
            {
              enabled: true,
              distance: 30,
            },
            {
              enabled: true,
              distance: "-30%",
              format: "{point.name}",
              style: {
                fontSize: "1.2em",
                textOutline: "none",
                opacity: 0.7,
              },
              filter: {
                operator: ">",
                property: "percentage",
                value: 5,
              },
              format: "{point.y:.1f}%",
              style: {
                fontSize: "12px",
                textOutline: "none",
                color: "#ffffff",
              },
            },
          ],
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}%</b>  </b><br/>',
      },
      colors: [color],
      series: [
        {
          data: pctArray,
        },
      ],
    });
  }, [pctArray]);

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className={`card card-outline`}>
                <div className="card-header">
                  <h3 className="card-title">
                    {tipe} ({nowDate})
                  </h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                    >
                      <i className={`fas fa-minus`} />
                    </button>
                  </div>
                </div>

                <div className={`card-body`}>
                  <figure className="highcharts-figure">
                    <div id="container1" className="lg:py-12 py-1"></div>
                  </figure>
                  {tipe == "STO" ? (
                    <>
                      {is_manager === "1" ? (
                        <section className="content">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-12">
                                <div className={`card card-outline`}>
                                  <div className="card-header">
                                    <h3 className="card-title">
                                      GRAFIK SALES BULAN ({nowDate})
                                    </h3>
                                    <div className="card-tools">
                                      <button
                                        type="button"
                                        className="btn btn-tool"
                                        data-card-widget="collapse"
                                      >
                                        <i className={`fas fa-minus`} />
                                      </button>
                                    </div>
                                  </div>

                                  <div className={`card-body`}>
                                    <figure className="highcharts-figure">
                                      <div
                                        id="container"
                                        className="lg:py-12 py-1"
                                      ></div>
                                    </figure>
                                  </div>
                                  {/* /.card-body */}
                                </div>
                                {/* /.card */}
                              </div>
                            </div>
                          </div>
                        </section>
                      ) : (
                        <figure
                          className="highcharts-figure"
                          style={{ display: "none" }}
                        >
                          <div id="container" className="lg:py-12 py-1"></div>
                        </figure>
                      )}
                    </>
                  ) : null}
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
          </div>
        </div>
      </section>

      <div ref={targetRef2}>
        {show2 ? (
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className={`card card-outline`}>
                    <div className="card-header">
                      <h3 className="card-title">
                        {name1} ({nowDate})
                      </h3>
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className={`fas fa-minus`} />
                        </button>
                      </div>
                    </div>

                    <div className={`card-body`}>
                      <figure className="highcharts-figure">
                        <div id="container2" className="lg:py-12 py-1"></div>
                      </figure>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
              </div>
            </div>
          </section>
        ) : (
          <figure className="highcharts-figure" style={{ display: "none" }}>
            <div id="container2" className="lg:py-12 py-1"></div>
          </figure>
        )}
      </div>

      <div ref={targetRef3}>
        {show3 ? (
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className={`card card-outline`}>
                    <div className="card-header">
                      <h3 className="card-title">
                        {name2} ({nowDate})
                      </h3>
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className={`fas fa-minus`} />
                        </button>
                      </div>
                    </div>

                    <div className={`card-body`}>
                      <figure className="highcharts-figure">
                        <div id="container3" className="lg:py-12 py-1"></div>
                      </figure>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
              </div>
            </div>
          </section>
        ) : (
          <figure className="highcharts-figure" style={{ display: "none" }}>
            <div id="container3" className="lg:py-12 py-1"></div>
          </figure>
        )}
      </div>

      <div ref={targetRef4}>
        {show4 ? (
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className={`card card-outline`}>
                    <div className="card-header">
                      <h3 className="card-title">
                        {name3} ({nowDate})
                      </h3>
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className={`fas fa-minus`} />
                        </button>
                      </div>
                    </div>

                    <div className={`card-body`}>
                      <figure className="highcharts-figure">
                        <div id="container4" className="lg:py-12 py-1"></div>
                      </figure>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
              </div>
            </div>
          </section>
        ) : (
          <figure className="highcharts-figure" style={{ display: "none" }}>
            <div id="container4" className="lg:py-12 py-1"></div>
          </figure>
        )}
      </div>

      <div ref={targetRef5}>
        {show5 ? (
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className={`card card-outline`}>
                    <div className="card-header">
                      <h3 className="card-title">
                        {name4} ({nowDate})
                      </h3>
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className={`fas fa-minus`} />
                        </button>
                      </div>
                    </div>

                    <div className={`card-body`}>
                      <figure className="highcharts-figure">
                        <div id="container5" className="lg:py-12 py-1"></div>
                      </figure>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
              </div>
            </div>
          </section>
        ) : (
          <figure className="highcharts-figure" style={{ display: "none" }}>
            <div id="container5" className="lg:py-12 py-1"></div>
          </figure>
        )}
      </div>

      {loader}
    </>
  );
}
export default MainDashboard;
