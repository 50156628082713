import React, { useEffect, useState, useRef } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../template/Navbar";
import FooterTemp from "../template/Footer";

function Home() {
  return (
    <>
      <Navbar />
      <div>
        <Outlet />
      </div>
      <FooterTemp />
    </>
  );
}

export default Home;
