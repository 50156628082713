import React, { useState, useEffect } from "react";
import {
  getDataRatioOutletUser,
  getDataGrowth,
} from "../../service/ratio/RatioService";
import Swal from "sweetalert2";
import Full from "../../load/Loading";
import "../../App.css";
import Highcharts from "highcharts";
import numeral from "numeral";

function ModalDetailSTO({
  onCloseModal,
  Search_Id,
  Search_Name,
  priode,
  rank,
  ranks,
  Id_type,
  idTeam,
  color,
  tipe,
}) {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const onCloseModalProperty = () => {
    onCloseModal();
  };

  const [dataUser, setDataUser] = useState([]);
  const [dataGrowth, setDataGrowth] = useState([]);

  const [growthTotalArea, setGrowthTotalArea] = useState("");
  const [growthYtdTotalArea, setGrowthYtdTotalArea] = useState("");

  const [selectedSaldo, setSelectedSaldo] = useState(""); // State to store selected wilayah
  const [priodeGrowth, setPriodeGrowth] = useState("");
  const [nameTitle, setNameTitle] = useState("");
  const [names, setName] = useState("");

  const [totalCurrentCt, setTotalCurrentCt] = useState(0);
  const [totalCurrentSaldo, setTotalCurrentSaldo] = useState(0);
  const [totalLastKU, setTotalLastKU] = useState(0);
  const [totalM3, setTotalM3] = useState(0);
  const [totalM2, setTotalM2] = useState(0);
  const [totalM1, setTotalM1] = useState(0);
  const [totalM, setTotalM] = useState(0);
  const [countNonZeroM3, setcountNonZeroM3] = useState(0);
  const [countNonZeroM2, setcountNonZeroM2] = useState(0);
  const [countNonZeroM1, setcountNonZeroM1] = useState(0);
  const [countNonZeroM, setcountNonZeroM] = useState(0);
  const [Percentage, setPercentage] = useState(0);

  const dataUsers = [];
  for (const key in dataUser) {
    if (Object.hasOwnProperty.call(dataUser, key)) {
      const element = dataUser[key];
      dataUsers.push(element);
    }
  }

  const handleSaldoChange = (e) => {
    setSelectedSaldo(e.target.value); // Update the selected wilayah
  };

  useEffect(() => {
    const filteredData = selectedSaldo
      ? dataUsers.filter((item) => item.status_saldo === selectedSaldo)
      : dataUsers;

    const newTotalCt = filteredData.reduce(
      (acc, item) => acc + parseFloat(item.current_ct),
      0
    );
    const newTotalSaldo = filteredData.reduce(
      (acc, item) => acc + parseFloat(item.current_saldo),
      0
    );
    const newTotalLastKU = filteredData.reduce(
      (acc, item) => acc + parseFloat(item.last_ku),
      0
    );
    const newTotalM3 = filteredData.reduce(
      (acc, item) => acc + parseFloat(item.m_3),
      0
    );
    const newTotalM2 = filteredData.reduce(
      (acc, item) => acc + parseFloat(item.m_2),
      0
    );
    const newTotalM1 = filteredData.reduce(
      (acc, item) => acc + parseFloat(item.m_1),
      0
    );
    const newTotalM = filteredData.reduce(
      (acc, item) => acc + parseFloat(item.m),
      0
    );
    const newSumY = filteredData.reduce(
      (acc, item) => acc + parseFloat(item.sum_y),
      0
    );
    const newSumLY = filteredData.reduce(
      (acc, item) => acc + parseFloat(item.sum_ly),
      0
    );

    const countNonZeroM3 = filteredData.filter(
      (item) => parseInt(item.m_3) !== 0
    ).length;

    const countNonZeroM2 = filteredData.filter(
      (item) => parseInt(item.m_2) !== 0
    ).length;

    const countNonZeroM1 = filteredData.filter(
      (item) => parseInt(item.m_1) !== 0
    ).length;

    const countNonZeroM = filteredData.filter(
      (item) => parseInt(item.m) !== 0
    ).length;

    let percentage;
    if (priodeGrowth !== "Growth YTD") {
      percentage = (
        (newTotalM / ((newTotalM1 + newTotalM2 + newTotalM3) / 3)) * 100 -
        100
      ).toFixed(2);
    } else {
      percentage = ((newSumY / newSumLY) * 100 - 100).toFixed(2);
    }

    setTotalCurrentCt(newTotalCt);
    setTotalCurrentSaldo(newTotalSaldo);
    setTotalLastKU(newTotalLastKU);
    setTotalM3(newTotalM3);
    setTotalM2(newTotalM2);
    setTotalM1(newTotalM1);
    setTotalM(newTotalM);
    setcountNonZeroM3(countNonZeroM3);
    setcountNonZeroM2(countNonZeroM2);
    setcountNonZeroM1(countNonZeroM1);
    setcountNonZeroM(countNonZeroM);
    setPercentage(percentage);
    // Perbarui total lainnya sesuai kebutuhan...
  }, [selectedSaldo, dataUsers]);

  const filteredData = selectedSaldo
    ? dataUsers.filter((item) => item.status_saldo === selectedSaldo)
    : dataUsers;

  const sortedDataOutletUser = filteredData.sort(
    (a, b) => a.current_saldo - b.current_saldo
  );

  useEffect(() => {
    showLoader();
    const payload = {
      id_type: Id_type,
      rank: rank ? rank : ranks,
      search_id: "",
      product_list_id: Search_Id,
      periode: priode,
      team_id: idTeam,
      type: tipe,
    };
    getDataGrowth(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataGrowth(res.data.data);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  }, [Search_Id]);

  const ratioOutlet = (priodes, status, priodeGrowth, nameTitle, Names) => {
    setPriodeGrowth(priodeGrowth);
    setNameTitle(nameTitle);
    setName(Names);
    showLoader();
    const payload = {
      id_type: Id_type,
      rank: rank ? rank : ranks,
      search_id: "",
      product_list_id: Search_Id,
      periode: priode,
      team_id: idTeam,
      periode_growth: priodes,
      status_growth: status,
      type: tipe,
    };
    getDataRatioOutletUser(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataUser(res.data.data);
          setGrowthTotalArea(res.data.growth_total);
          setGrowthYtdTotalArea(res.data.growth_ytd_total);
          initializeDataTables();
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const formatToRupiah = (value) => {
    return numeral(value).format("0,0"); // Format angka dengan koma sebagai pemisah ribuan
  };

  const initializeDataTables = () => {
    // Initialize DataTables library here after data is fetched and rendered
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/content.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const script = document.createElement("script");
      script.src = `${process.env.PUBLIC_URL}/js/content.js`;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [dataUser]);

  useEffect(() => {
    if (sortedDataOutletUser.length !== 0) {
      const scripts = [];
      const datatableScript = document.createElement("script");
      datatableScript.async = true;
      datatableScript.textContent = `$(document).ready(function() {
            $('#tableBdu').DataTable({
                "retrieve": true,
                "paging": false,
                "lengthChange": false,
                "searching": true,
                "ordering": true,
                "info": false,
                "autoWidth": false,
                "responsive": false,
                "columnDefs": [
                  {
                    "targets": 4, 
                    "orderable": false,
                  }
                ]

            });
        });`;

      document.body.appendChild(datatableScript);
      scripts.push(datatableScript);

      return () => {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
      };
    }
  }, [sortedDataOutletUser]);

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-60 z-30 max-h-[100vh]"></div>

      <div
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-40 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className=" pb-12 mx-[3%] my-[2%] max-w-8xl">
          {/* Modal content */}
          <div className="bg-white rounded-lg shadow">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <button
                onClick={onCloseModalProperty}
                type="button"
                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xl w-12 h-12 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="overflow-y-auto overflow-x-auto max-h-[800px]">
              <div className="p-4 md:p-5 relative ">
                <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                  <h3 className="font-semibold text-lg mx-2 ">
                    User Growth{" "}
                    <span className="text-redBrave">{Search_Name}</span>
                  </h3>
                  <div>
                    <div className="card-body">
                      <table
                        className="table table-bordered table-hover table-scroll mb-3"
                        style={{
                          fontSize: "12px",
                          fontWeight: "semiBold",
                          overflow: "scroll",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#437998",
                            color: "white",
                            textAlign: "center",
                            top: "0",
                            zIndex: "10",
                            position: "sticky",
                            fontWeight: "reguler",
                          }}
                        >
                          <tr>
                            <th
                              style={{
                                left: "0",
                                zIndex: "5",
                                position: "sticky",
                                verticalAlignAlign: "middle",
                                backgroundColor: "#437998",
                              }}
                            ></th>
                            <th
                              style={{
                                left: "60px",
                                zIndex: "10",
                                position: "sticky",
                                verticalAlignAlign: "middle",
                                backgroundColor: "#437998",
                              }}
                            >
                              DISGROWTH
                            </th>
                            <th>STAGNAN</th>
                            <th>GROWTH</th>
                            <th>TOTAL</th>
                          </tr>
                        </thead>

                        <tbody style={{ textAlign: "center" }}>
                          {dataGrowth.map((item, index) => (
                            <tr key={index}>
                              <td>{item.status}</td>
                              <td
                                style={{
                                  textDecoration: "underline",
                                  fontStyle: "italic",
                                  color: "#3b82f6",
                                  cursor: "pointer",
                                  left: "60px",
                                  zIndex: "5",
                                  position: "sticky",
                                  backgroundColor: "#ffffff",
                                }}
                              >
                                <span
                                  onClick={() =>
                                    ratioOutlet(
                                      item.id,
                                      "0",
                                      item.status,
                                      item.disgrowth,
                                      "Disgrowth"
                                    )
                                  }
                                >
                                  {item.disgrowth}
                                </span>
                              </td>
                              <td
                                style={{
                                  textDecoration: "underline",
                                  fontStyle: "italic",
                                  color: "#3b82f6",
                                  cursor: "pointer",
                                  left: "60px",
                                  zIndex: "5",
                                  position: "sticky",
                                  backgroundColor: "#ffffff",
                                }}
                              >
                                <span
                                  onClick={() =>
                                    ratioOutlet(
                                      item.id,
                                      "1",
                                      item.status,
                                      item.stagnan,
                                      "Stagnan"
                                    )
                                  }
                                >
                                  {item.stagnan}
                                </span>
                              </td>
                              <td
                                style={{
                                  textDecoration: "underline",
                                  fontStyle: "italic",
                                  color: "#3b82f6",
                                  cursor: "pointer",
                                  left: "60px",
                                  zIndex: "5",
                                  position: "sticky",
                                  backgroundColor: "#ffffff",
                                }}
                              >
                                <span
                                  onClick={() =>
                                    ratioOutlet(
                                      item.id,
                                      "2",
                                      item.status,
                                      item.growth,
                                      "Growth"
                                    )
                                  }
                                >
                                  {item.growth}
                                </span>
                              </td>
                              <td
                                style={{
                                  textDecoration: "underline",
                                  fontStyle: "italic",
                                  color: "#3b82f6",
                                  cursor: "pointer",
                                  left: "60px",
                                  zIndex: "5",
                                  position: "sticky",
                                  backgroundColor: "#ffffff",
                                }}
                              >
                                <span
                                  onClick={() =>
                                    ratioOutlet(
                                      item.id,
                                      "3",
                                      item.status,
                                      item.total,
                                      "Total"
                                    )
                                  }
                                >
                                  {item.total}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {dataUser.length !== 0 ? (
                      <div>
                        <h3 className="font-semibold text-lg mx-2 ">
                          {priodeGrowth} - {names} :{" "}
                          <span className="text-redBrave">{nameTitle}</span>
                          <span className="text-redBrave"></span>
                        </h3>

                        <div className="card-body">
                          <table
                            className="table table-bordered table-hover table-scroll"
                            id="tableBdu"
                            style={{
                              fontSize: "12px",
                              fontWeight: "semiBold",
                              display: "block",
                              overflow: "scroll",
                              height: "420px",
                              width: "100%",
                              position: "relative",
                            }}
                          >
                            <thead
                              style={{
                                backgroundColor: "#437998",
                                color: "white",
                                textAlign: "center",
                                top: "0",
                                zIndex: "10",
                                position: "sticky",
                                fontWeight: "reguler",
                              }}
                            >
                              <tr>
                                <th
                                  style={{
                                    left: "0",
                                    zIndex: "5",
                                    position: "sticky",
                                    verticalAlignAlign: "middle",
                                    backgroundColor: "#437998",
                                  }}
                                >
                                  NO
                                </th>
                                <th
                                  style={{
                                    left: "60px",
                                    zIndex: "10",
                                    position: "sticky",
                                    verticalAlignAlign: "middle",
                                    backgroundColor: "#437998",
                                  }}
                                  width="20%"
                                >
                                  UID
                                </th>
                                <th>CT</th>
                                <th>RB</th>
                                {/* <th>SLD</th> */}
                                <th>
                                  {" "}
                                  <select
                                    onChange={handleSaldoChange}
                                    className="text-xs bg-gray-50 border border-gray-300 rounded-lg py-2 text-black uppercase"
                                    value={selectedSaldo}
                                  >
                                    <option value="">Semua SLD</option>
                                    {/* Generate options dynamically based on available data */}
                                    {[
                                      ...new Set(
                                        dataUsers.map(
                                          (item) => item.status_saldo
                                        )
                                      ),
                                    ].map((status, index) => (
                                      <option key={index} value={status}>
                                        {status}
                                      </option>
                                    ))}
                                  </select>
                                </th>
                                <th>KU</th>
                                <th>TNR</th>
                                <th>M-3</th>
                                <th>M-2</th>
                                <th>M-1</th>
                                <th>M</th>
                                <th>
                                  <span className="uppercase">
                                    {priodeGrowth}
                                  </span>
                                </th>
                              </tr>
                            </thead>

                            <tbody style={{ textAlign: "right" }}>
                              {sortedDataOutletUser.map((list, index) => {
                                return (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        left: "0px",
                                        zIndex: "5",
                                        position: "sticky",
                                        backgroundColor: "#ffffff",
                                      }}
                                      width={"1%"}
                                    >
                                      {index + 1}.
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        left: "60px",
                                        zIndex: "5",
                                        position: "sticky",
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      }}
                                      className="relative group"
                                    >
                                      {list.user_id === null ||
                                      list.user_name === null ? (
                                        <>Not Connected</>
                                      ) : (
                                        <>
                                          {list.user_id} / {list.user_name}
                                          <span className="absolute left-6 bottom-0 mb-0 hidden w-max p-2 text-xs text-white bg-gray-700 rounded-md shadow-lg group-hover:block ">
                                            Tim User
                                            <br /> {list.struktur}
                                          </span>
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      <span
                                        className={`${
                                          parseFloat(list.current_ct) < 0
                                            ? "text-red-500"
                                            : ""
                                        }`}
                                      >
                                        {formatToRupiah(list.current_ct)}
                                      </span>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {" "}
                                      {list.current_rb === "100.00"
                                        ? "100 %"
                                        : `${list.current_rb} %`}
                                    </td>
                                    <td>
                                      <span
                                        className={`${
                                          parseFloat(list.current_saldo) < 0
                                            ? "text-red-500"
                                            : ""
                                        }`}
                                      >
                                        {formatToRupiah(list.current_saldo)}
                                      </span>
                                    </td>
                                    {/* <td>
                                      <span className="text-center">
                                        {list.status_saldo}
                                      </span>
                                    </td> */}
                                    <td>
                                      <span
                                        className={`${
                                          parseFloat(list.last_ku) < 0
                                            ? "text-red-500"
                                            : ""
                                        }`}
                                      >
                                        {formatToRupiah(list.last_ku)}
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <span
                                        className={`${
                                          parseFloat(list.lifetime) < 0
                                            ? "text-red-500"
                                            : ""
                                        }`}
                                      >
                                        {list.lifetime}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={`${
                                          parseFloat(list.m_3) < 0
                                            ? "text-red-500"
                                            : ""
                                        }`}
                                      >
                                        {formatToRupiah(list.m_3)}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={`${
                                          parseFloat(list.m_2) < 0
                                            ? "text-red-500"
                                            : ""
                                        }`}
                                      >
                                        {formatToRupiah(list.m_2)}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        className={`${
                                          parseFloat(list.m_1) < 0
                                            ? "text-red-500"
                                            : ""
                                        }`}
                                      >
                                        {formatToRupiah(list.m_1)}
                                      </span>
                                    </td>
                                    <td className="text-green-500">
                                      {" "}
                                      <span
                                        className={`${
                                          parseFloat(list.m) < 0
                                            ? "text-red-500"
                                            : ""
                                        }`}
                                      >
                                        {formatToRupiah(list.m)}
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      {priodeGrowth !== "Growth YTD" ? (
                                        <span
                                          className={`${
                                            parseFloat(list.growth_cur) < 0
                                              ? "text-red-500"
                                              : ""
                                          }`}
                                        >
                                          {list.growth_cur} %
                                        </span>
                                      ) : (
                                        <span
                                          className={`${
                                            parseFloat(list.growth_ytd) < 0
                                              ? "text-red-500"
                                              : ""
                                          }`}
                                        >
                                          {list.growth_ytd} %
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            <tfoot
                              style={{ textAlign: "right" }}
                              className="sticky bottom-0 z-10"
                            >
                              <tr className="bg-white border border-slate-800 font-bold">
                                <td
                                  colSpan={"2"}
                                  style={{
                                    textAlign: "center",
                                    left: "0px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  USER TRANSAKSI
                                </td>
                                <td colSpan={"5"}></td>
                                <td>
                                  {countNonZeroM3} / {dataUsers.length}
                                </td>
                                <td>
                                  {countNonZeroM2} / {dataUsers.length}
                                </td>
                                <td>
                                  {countNonZeroM1} / {dataUsers.length}
                                </td>
                                <td>
                                  {countNonZeroM} / {dataUsers.length}
                                </td>
                                <td colSpan={"1"}></td>
                              </tr>
                              <tr className="bg-white border border-slate-800 font-bold">
                                <td
                                  colSpan={"2"}
                                  style={{
                                    textAlign: "center",
                                    left: "0px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  TOTAL
                                </td>
                                <td>
                                  {" "}
                                  <span
                                    className={`${
                                      parseFloat(totalCurrentCt) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(totalCurrentCt)}
                                  </span>
                                </td>
                                <td style={{ backgroundColor: "white" }}></td>
                                <td>
                                  {" "}
                                  <span
                                    className={`${
                                      parseFloat(totalCurrentSaldo) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(totalCurrentSaldo)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(totalLastKU) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(totalLastKU)}
                                  </span>
                                </td>
                                <td style={{ backgroundColor: "white" }}></td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(totalM3) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(totalM3)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(totalM2) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(totalM2)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(totalM1) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(totalM1)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(totalM) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(totalM)}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    backgroundColor: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  <span
                                    className={`${
                                      parseFloat(Percentage) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {Percentage} %
                                  </span>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
}

export default ModalDetailSTO;
