import React, { useState, useEffect } from "react";
import {
  GetDetailRank,
  GetNextTable,
  GetNextTable2,
  GetNextTable3,
} from "../../service/ratio/RatioService";
import Swal from "sweetalert2";
import Full from "../../load/Loading";
import "../../App.css";
import Highcharts from "highcharts";
import numeral from "numeral";

function ModalDetailRank({
  onCloseModal,
  Search_Id,
  Search_Name,
  priode,
  rank,
  ranks,
  Id_type,
  idTeam,
  color,
}) {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const onCloseModalProperty = () => {
    onCloseModal();
  };

  const [dataRank, setDataRank] = useState({});
  const [totalKontribusi, setDataTotalKontribusi] = useState("");

  const dataRankList = [];
  for (const key in dataRank) {
    if (Object.hasOwnProperty.call(dataRank, key)) {
      const element = dataRank[key];
      dataRankList.push(element);
    }
  }

  const sortedDataRank = dataRankList.sort((a, b) => b.m - a.m);

  useEffect(() => {
    showLoader();
    const payload = {
      rank: rank ? rank : ranks,
      periode: priode,
      product_list_id: Search_Id,
    };
    GetDetailRank(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataRank(res.data.data);
          setDataTotalKontribusi(res.data.kontribusi_total);
          initializeDataTables();
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  }, [Search_Id]);

  const formatToRupiah = (value) => {
    return numeral(value).format("0,0"); // Format angka dengan koma sebagai pemisah ribuan
  };

  // Inisialisasi variabel total
  let totalM = 0;

  // Map dataChanel dan tambahkan nilai ke total
  dataRankList.forEach((list) => {
    totalM += parseInt(list.m);
  });

  const initializeDataTables = () => {
    // Initialize DataTables library here after data is fetched and rendered
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/content.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const script = document.createElement("script");
      script.src = `${process.env.PUBLIC_URL}/js/content.js`;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [dataRank]);

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-60 z-30 max-h-[100vh]"></div>

      <div
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-40 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className=" pb-12 mx-[3%] my-[2%] max-w-8xl">
          {/* Modal content */}
          <div className="bg-white rounded-lg shadow">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <button
                onClick={onCloseModalProperty}
                type="button"
                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xl w-12 h-12 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="overflow-y-auto overflow-x-auto max-h-[800px]">
              <div className="p-4 md:p-5 relative ">
                <figure
                  className="highcharts-figure"
                  style={{ display: "none" }}
                >
                  <div id="containerPie" className="lg:py-6"></div>
                </figure>

                <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                  <h3 className="font-semibold text-lg mx-2 ">
                    Rank Penjualan{" "}
                    <span className="text-redBrave">{Search_Name}</span>
                  </h3>
                  <div>
                    <div className="card-body">
                      <table
                        className="table table-bordered table-hover table-scroll"
                        id="example90"
                        style={{
                          fontSize: "12px",
                          fontWeight: "semiBold",
                          display: "block",
                          overflow: "scroll",
                          height: "420px",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#DD8E6B",
                            color: "white",
                            textAlign: "center",
                            top: "0",
                            zIndex: "10",
                            position: "sticky",
                            fontWeight: "reguler",
                          }}
                        >
                          <tr className="text-center">
                            <th
                              style={{
                                left: "0",
                                zIndex: "5",
                                position: "sticky",
                                verticalAlignAlign: "middle",
                                backgroundColor: "#DD8E6B",
                              }}
                            >
                              NO
                            </th>
                            <th>MR</th>
                            <th>SPV</th>
                            <th>AM</th>
                            <th>RM</th>
                            <th>SALES ( M )</th>
                            <th>KONTRIBUSI</th>
                          </tr>
                        </thead>

                        <tbody style={{ textAlign: "right" }}>
                          {sortedDataRank.map((list, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    textAlign: "center",
                                    left: "0px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                  width={"1%"}
                                >
                                  {index + 1}.
                                </td>
                                <td className="text-center">{list.team_mr}</td>
                                <td className="text-center">{list.team_spv}</td>
                                <td className="text-center">{list.team_am}</td>
                                <td className="text-center">{list.team_rm}</td>
                                <td className="text-green-500">
                                  {" "}
                                  <span
                                    className={`${
                                      parseFloat(list.m) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m)}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {list.kontribusi} %
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot
                          style={{ textAlign: "right" }}
                          className="sticky bottom-0 z-10"
                        >
                          <tr className="bg-white border border-slate-800 font-bold">
                            <td
                              colSpan={"5"}
                              style={{
                                textAlign: "center",
                                left: "0px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              TOTAL
                            </td>

                            <td>
                              <span
                                className={`${
                                  parseFloat(totalM) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(totalM)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(totalKontribusi) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalKontribusi)} %
                              </span>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
}

export default ModalDetailRank;
