import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import Full from "../../load/Loading";
import Swal from "sweetalert2";
import moment from "moment";
import numeral from "numeral";
import { getTeamName, PostEntry } from "../../service/ratio/RatioService";
import ModalEntry from "./ModalEntry";

function EntryData({ setNameTeam, rank_name, team_id, division_id, monthNow }) {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const [targetAwal, setTargetAwal] = useState("");
  const [targetEstimasi, setTargetEstimasi] = useState("");
  const [dataProduk, setDataProduk] = useState([{}]);
  const [estimasiTarget, setEstimasiTarget] = useState([]);
  const [EntryId, setEntry_Id] = useState("");
  const [EntryName, setEntry_Name] = useState("");
  const [TargetEstimasi, setEntryTargetEstimasi] = useState("");
  const [messageData, setMessage] = useState("");
  const [messageData2, setMessage2] = useState("");
  const [statusData, setStatus] = useState("");

  const handleInputChange = (productId, value) => {
    // Hapus tanda koma dan hanya izinkan angka
    const numericValue = value.replace(/\D/g, "");
    setEstimasiTarget((prev) => {
      const index = prev.findIndex(
        (item) => item.product_list_id === productId
      );
      if (index !== -1) {
        const updatedArray = [...prev];
        updatedArray[index].target_estimasi = numericValue;
        return updatedArray;
      } else {
        return [
          ...prev,
          { product_list_id: productId, target_estimasi: numericValue },
        ];
      }
    });
  };

  const calculateInUnit = (userId) => {
    const userData =
      estimasiTarget.find((item) => item.product_list_id === userId) || {};
    const inUnit = parseInt(userData.target_estimasi, 10) || 0;

    // Kalkulasi estimasi berdasarkan inputan
    return inUnit;
  };
  const calculateInValue = (userId) => {
    const userData =
      estimasiTarget.find((item) => item.product_list_id === userId) || {};
    const inUnit = parseInt(userData.target_estimasi, 10);

    // Kalkulasi estimasi berdasarkan inputan
    return inUnit;
  };

  useEffect(() => {
    showLoader();

    const payload = {
      rank: division_id,
      team_id: team_id,
    };
    getTeamName(payload)
      .then((res) => {
        if (res.data.success == true) {
          setStatus(res.data.success);
          setDataProduk(res.data.data.list_product);
          setNameTeam(res.data.team_name);
          setTargetAwal(res.data.data.target_awal);
          setTargetEstimasi(res.data.data.subtotal_target_estimasi);
          initializeDataTables();
        } else {
          setMessage(res.data.message_1);
          setMessage2(res.data.message_2);
          setStatus(res.data.success);
          setNameTeam(res.data.team_name);
        }

        hideLoader();
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
        hideLoader();
      });
  }, [rank_name]);

  const sendEntry = () => {
    showLoader();
    const payload = {
      rank: rank_name,
      team_id: team_id,
      list_product: estimasiTarget,
    };

    PostEntry(payload)
      .then((res) => {
        if (res.data.success == true) {
          Swal.fire({
            title: res.data.message,
            showConfirmButton: false,
            icon: "success",
          });
          hideLoader();
          window.location.reload();
        } else {
          Swal.fire({
            title: res.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          hideLoader();
          window.location.reload();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const formatToRupiah = (value) => {
    return numeral(value).format("0,0");
  };

  const [modalIsOpenUser, setModalIsOpenUser] = useState(false);

  const openModalEntry = (id, name, targetEstimasi) => {
    setEntry_Id(id);
    setEntry_Name(name);
    setEntryTargetEstimasi(targetEstimasi);
    setModalIsOpenUser(true);
  };

  const closeModalEntry = () => {
    setModalIsOpenUser(false);
  };

  const initializeDataTables = () => {
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/content.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  return (
    <>
      {statusData === true ? (
        <div>
          <div className="mb-6">
            <div>
              <table>
                <tr>
                  <th className="list-disc text-[16px] lg:text-[18px] font-bold">
                    Target Bulan{" "}
                    {moment(monthNow).format("MMMM", {
                      locale: "id",
                    })}
                  </th>
                  <th className="list-disc text-[16px] lg:text-[18px] font-bold ">
                    {" "}
                    : {""}
                    <span
                      className={`${
                        parseFloat(targetAwal) < 0 ? "text-red-500" : ""
                      }`}
                    >
                      Rp. {formatToRupiah(targetAwal)}
                    </span>
                  </th>
                </tr>
              </table>
            </div>
          </div>
          <div>
            <h5 className="font-semibold text-[16px] lg:text-[18px] mb-3">
              Pilih produk - produk untuk ditargetkan !
            </h5>
            <ul className="list-disc pl-5 text-[14px] lg:text-[16px] font-extralight mb-3">
              <li>
                Silakan isi Estimasi Target{" "}
                <span className="font-bold">“In Box“</span> pada produk, setelah
                itu silakan klik tombol{" "}
                <span className="font-bold">“SIMPAN”</span>.
              </li>
              <li>
                Kemudian klik tombol{" "}
                <span className="font-bold">“BREAKDOWN”</span>, untuk{" "}
                <span className="italic">breakdown</span> target user.
              </li>
            </ul>
            <div className="border-[1px] border-[#437998] rounded-xl p-6 mb-4">
              <form className="space-y-4" onSubmit={handleSubmit(sendEntry)}>
                <div className="card-body" style={{ marginTop: "-20px" }}>
                  <table
                    className="table table-bordered table-hover table-scroll"
                    id="example400"
                    style={{
                      fontSize: "12px",
                      fontWeight: "semiBold",
                      display: "block",
                      overflow: "scroll",
                      height: "480px",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#527C7F",
                        color: "white",
                        textAlign: "center",
                        top: "0px",
                        zIndex: "10",
                        position: "sticky",
                        fontWeight: "reguler",
                      }}
                    >
                      <tr>
                        <th
                          rowSpan={2}
                          width="1%"
                          style={{
                            left: "0",
                            zIndex: "5",
                            position: "sticky",
                            verticalAlign: "middle",
                            backgroundColor: "#527C7F",
                          }}
                        >
                          NO
                        </th>
                        <th
                          rowSpan={"2"}
                          width="9.6%"
                          style={{
                            left: "62px",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlign: "middle",
                            backgroundColor: "#527C7F",
                          }}
                        >
                          PRODUCT
                        </th>
                        <th
                          colSpan={3}
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          ESTIMASI TARGET
                        </th>
                        <th
                          rowSpan={2}
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          ESTIMASI USER
                        </th>
                        <th
                          rowSpan={2}
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          AKSI
                        </th>
                        <th colSpan={2}>TIMESTAMP</th>
                      </tr>
                      <tr>
                        <th>IN BOX</th>
                        <th>IN UNIT</th>
                        <th>VALUE</th>
                        <th>INPUT</th>
                        <th>UPDATE</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "right" }}>
                      {dataProduk?.map((list, index) => {
                        const ValueEntry = estimasiTarget.find(
                          (item) =>
                            item.product_list_id === list.product_list_id
                        )?.target_estimasi;
                        const valueBox =
                          list.target_estimasi_box !== "0"
                            ? list.target_estimasi_box
                            : "";
                        const inUnit =
                          calculateInUnit(list.product_list_id) *
                          list.quantity_per_box;
                        const ValueEst =
                          calculateInValue(list.product_list_id) *
                          list.price_box;

                        return (
                          <tr
                            key={index}
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                              fontSize: "13px",
                            }}
                          >
                            <td
                              width={"1%"}
                              style={{
                                textAlign: "center",
                                left: "0px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              {index + 1}.
                            </td>

                            <td
                              width={"10%"}
                              style={{
                                textAlign: "center",
                                cursor: "pointer",
                                left: "62px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              {list.product_list_name}
                            </td>
                            <td width={"10%"}>
                              <input
                                type="text"
                                maxLength={4}
                                value={
                                  ValueEntry !== undefined
                                    ? ValueEntry
                                    : valueBox
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    list.product_list_id,
                                    e.target.value
                                  )
                                }
                                className="p-1 w-full border-1 rounded-lg max-w-60 text-sm text-left"
                              />
                            </td>
                            <td
                              width={"15%"}
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {inUnit || list.target_estimasi_unit}
                            </td>
                            <td
                              width={"15%"}
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {formatToRupiah(
                                ValueEst || list.target_estimasi_value
                              )}
                            </td>
                            <td
                              width={"15%"}
                              style={{
                                textAlign: "right",
                              }}
                            >
                              {parseInt(list.user_estimasi) >=
                              parseInt(list.target_estimasi_value) ? (
                                <span>
                                  {formatToRupiah(list.user_estimasi)}
                                </span>
                              ) : (
                                <span className="text-redBrave">
                                  {formatToRupiah(list.user_estimasi)}
                                </span>
                              )}
                            </td>
                            <td width={"5%"}>
                              {list.button !== 0 ? (
                                <div
                                  onClick={() =>
                                    openModalEntry(
                                      list.product_list_id,
                                      list.product_list_name,
                                      list.target_estimasi_value
                                    )
                                  }
                                  className={`w-full lg:w-full  cursor-pointer rounded-lg border-none border-[#5077C4] ${
                                    parseInt(list.user_estimasi) >=
                                    parseInt(list.target_estimasi_value)
                                      ? "bg-[#71AF7B]"
                                      : "bg-[#5077C4]"
                                  }`}
                                >
                                  <h5 className="text-center font-normal p-2 text-white">
                                    Breakdown
                                  </h5>
                                </div>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td>
                              {" "}
                              {list.input_date !== 0 ? (
                                <span>{list.input_date}</span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td>
                              {list.update_date !== 0 ? (
                                <span>{list.update_date}</span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="card-body" style={{ marginTop: "-20px" }}>
                  <table className="table">
                    <thead></thead>
                    <tbody
                      style={{
                        textAlign: "center",
                        fontWeight: "reguler",
                        fontSize: "14px",
                      }}
                    >
                      <tr>
                        <th
                          rowSpan={2}
                          style={{
                            textAlign: "center", // Horizontal centering
                            verticalAlign: "middle", // Vertical centering
                          }}
                        >
                          TOTAL ESTIMASI VALUE PRODUK
                        </th>
                        <th
                          style={{
                            textAlign: "center", // Horizontal centering
                            verticalAlign: "middle", // Vertical centering
                          }}
                        >
                          {parseInt(targetEstimasi) < parseInt(targetAwal) ? (
                            <span className="text-center font-normal text-redBrave">
                              Rp. {formatToRupiah(targetEstimasi)}
                            </span>
                          ) : (
                            <span> Rp. {formatToRupiah(targetEstimasi)}</span>
                          )}
                        </th>

                        <th
                          style={{
                            textAlign: "center", // Horizontal centering
                            verticalAlign: "middle", // Vertical centering
                          }}
                        >
                          {parseInt(targetEstimasi) < parseInt(targetAwal) ? (
                            <span className="text-center font-normal text-redBrave">
                              (Estimasi Target masih kurang dari target bulan{" "}
                              {moment(monthNow).format("MMMM", {
                                locale: "id",
                              })}
                              )
                            </span>
                          ) : null}
                        </th>

                        <th width={"10%"} rowSpan={2}>
                          <button
                            className={`w-full lg:w-full   rounded-lg border-none border-redBrave bg-redBrave`}
                          >
                            <h5 className="text-center font-normal p-2 text-white">
                              Simpan
                            </h5>
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center py-24">
          <h4>{messageData}</h4>
          <br></br>
          <b>{messageData2}</b>
        </div>
      )}

      {loader}

      {modalIsOpenUser ? (
        <ModalEntry
          onCloseModal={closeModalEntry}
          Entry_Id={EntryId}
          Entry_Name={EntryName}
          Target_Estimasi={TargetEstimasi}
          rank={division_id}
          teamId={team_id}
          setModalIsOpenUser={setModalIsOpenUser}
        />
      ) : null}
    </>
  );
}
export default EntryData;
