import React, { useState, useEffect, useRef } from "react";
import numeral from "numeral";

function StockSubdistProduk({ dataBatch, namaProduk, unitBatch }) {
  useEffect(() => {
    initializeDataTables();
  }, [dataBatch]);

  //konversi nilai
  const formatToRupiah = (value) => {
    return numeral(value).format("0,0"); // Format angka dengan koma sebagai pemisah ribuan
  };

  const initializeDataTables = () => {
    // Initialize DataTables library here after data is fetched and rendered
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/content.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  return (
    <>
      {dataBatch.length > 0 ? (
        <section className="">
          <div style={{ marginTop: "30px" }}>
            <div className="border-[1px] border-[#437998] rounded-xl p-2">
              <h3 className="font-semibold text-lg mx-2">
                Batch - <span className="text-red-600">{namaProduk}</span>
              </h3>
              <div className="">
                <div className="card-body">
                  <table
                    className="table table-bordered table-hover table-scroll"
                    id="example502"
                    style={{
                      fontSize: "12px",
                      fontWeight: "semiBold",
                      display: "block",
                      overflow: "scroll",
                      height: "420px",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#736098",
                        color: "white",
                        textAlign: "center",
                        top: "0",
                        zIndex: "10",
                        position: "sticky",
                        fontWeight: "reguler",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            left: "0",
                            zIndex: "5",
                            position: "sticky",
                            verticalAlign: "middle",
                            backgroundColor: "#736098",
                          }}
                        >
                          NO
                        </th>
                        <th
                          style={{
                            left: "40px",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlign: "middle",
                            backgroundColor: "#736098",
                          }}
                        >
                          NO BATCH
                        </th>

                        <th
                          style={{
                            left: "60px",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlign: "middle",
                            backgroundColor: "#736098",
                          }}
                        >
                          TANGGAL EXPIRED
                        </th>
                        <th>UNIT</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "right" }}>
                      {dataBatch.map((list, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              textAlign: "center",
                              left: "0px",
                              zIndex: "5",
                              position: "sticky",
                              backgroundColor: "#ffffff",
                            }}
                            width={"1%"}
                          >
                            {index + 1}.
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              left: "40px",
                              zIndex: "5",
                              position: "sticky",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {list.BatchNo}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              left: "60px",
                              zIndex: "5",
                              position: "sticky",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {list.ExpDate}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <span
                              className={`${
                                parseFloat(list.UnitStock) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {formatToRupiah(list.UnitStock)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot className="sticky bottom-0 z-10">
                      <tr className="bg-white border border-slate-800 font-bold">
                        <th colSpan={"3"} className="text-center">
                          TOTAL
                        </th>
                        <th className="text-center">
                          {" "}
                          <span
                            className={`${
                              parseFloat(unitBatch) < 0 ? "text-red-500" : ""
                            }`}
                          >
                            {unitBatch}
                          </span>
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}

export default StockSubdistProduk;
