import React from "react";

const FooterTemp = () => {
  const currentTime = new Date().getFullYear();
  return (
    <>
      <div className="bg-black fixed bottom-0 left-0 w-full p-2 z-10">
        <div className="text-center">
          <h3 className="text-xs font-extralight pt-2">
            Copyright©{currentTime}
          </h3>
        </div>
      </div>
    </>
  );
};

export default FooterTemp;
