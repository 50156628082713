import React, { useEffect } from "react";
import { Navigate, Outlet, redirect } from "react-router-dom";

function ProtectedRoutes() {
  const hasToken = localStorage.getItem("token");

  return (
    <React.Fragment>
      {hasToken ? <Outlet /> : <Navigate replace to="/" />}
    </React.Fragment>
  );
}

export default ProtectedRoutes;
