import React, { useState, useEffect } from "react";
import { getDataLog } from "../../service/ratio/RatioService";
import Swal from "sweetalert2";
import Full from "../../load/Loading";
import "../../App.css";
import numeral from "numeral";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ModalUserLog({ onCloseModal, rank_name, team_id }) {
  //loading
  const [loader, showLoader, hideLoader] = Full();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dataLog, setDataLog] = useState([]);
  const dateStart = new Date(startDate);
  const dateEnd = new Date(endDate);

  const formatDateStart = (dateStart) => {
    const year = dateStart.getFullYear();
    const month = (dateStart.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-11, add 1 for 1-12
    const day = dateStart.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const formatDateEnd = (dateEnd) => {
    const year = dateEnd.getFullYear();
    const month = (dateEnd.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-11, add 1 for 1-12
    const day = dateEnd.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const DataLog = () => {
    showLoader();
    if (startDate === " " || endDate === "") {
      Swal.fire({
        title: "Silahkan pilih Tanggal!",
        showConfirmButton: false,
        icon: "warning",
      });
      hideLoader();
    } else {
      const payload = {
        rank_name: rank_name,
        team_id: team_id,
        start_date: formatDateStart(dateStart),
        end_date: formatDateEnd(dateEnd),
      };

      getDataLog(payload)
        .then((res) => {
          if (res.data.success == true) {
            setDataLog(res.data.data);
          }
          hideLoader();
        })
        .catch((err) => {
          if (err.response.data.message == "Expired token") {
            Swal.fire({
              title: err.response.data.message,
              showConfirmButton: false,
              icon: "warning",
            });
            localStorage.removeItem("token");
            localStorage.removeItem("rank_name");
            localStorage.removeItem("division_id");
            localStorage.removeItem("team_id");
            localStorage.removeItem("id_type");
            localStorage.removeItem("rank");
            localStorage.removeItem("color");
            localStorage.removeItem("is_manager");
            window.location = "/";
            hideLoader();
          }
          hideLoader();
        });
    }
  };

  useEffect(() => {
    if (dataLog.length !== 0) {
      const scripts = [];
      const datatableScript = document.createElement("script");
      datatableScript.async = true;
      datatableScript.textContent = `$(document).ready(function() {
            $('#tableLog1').DataTable({
                "retrieve": true,
                "paging": false,
                "lengthChange": false,
                "searching": true,
                "ordering": true,
                "info": false,
                "autoWidth": false,
                "responsive": false,
            });
        });`;

      document.body.appendChild(datatableScript);
      scripts.push(datatableScript);

      return () => {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
      };
    }
  }, [dataLog]);
  return (
    <>
      <div className="fixed inset-0 bg-black opacity-60 z-30 max-h-[100vh]"></div>

      <div
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-40 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className=" pb-12 mx-[3%] my-[2%] max-w-8xl">
          {/* Modal content */}
          <div className="bg-white rounded-lg shadow ">
            {/* Modal header */}
            <div className="flex items-center justify-between p-1 md:p-5 border-b rounded-t dark:border-gray-600">
              <button
                onClick={onCloseModal}
                type="button"
                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xl w-12 h-12 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-2 md:p-5 relative ">
              <h5 className="font-bold text-[20px] mb-3">User Log</h5>
              <div className="w-full lg:w-full lg:my-6 lg:mx-2 my-12">
                <div className="flex flex-col sm:flex-row items-center gap-4">
                  <h5 className="flex font-regular text-[16px] mb-0">
                    Tanggal:
                  </h5>
                  <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 w-full">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      startDate={startDate}
                      endDate={endDate}
                      popperPlacement="bottom-end"
                      selectsStart
                      dateFormat="dd MMMM yyyy"
                      placeholderText="Start Date"
                      className="p-2 border rounded-md w-full sm:w-auto"
                    />
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      startDate={startDate}
                      endDate={endDate}
                      selectsEnd
                      minDate={startDate}
                      popperPlacement="bottom-end"
                      dateFormat="dd MMMM yyyy"
                      placeholderText="End Date"
                      className="p-2 border rounded-md w-full sm:w-auto"
                    />
                    <button
                      onClick={() => DataLog()}
                      className="bg-redBrave text-white px-4 py-2 rounded-md w-full sm:w-auto"
                    >
                      Proses
                    </button>
                  </div>
                </div>
              </div>
              {dataLog.length > 0 ? (
                <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                  <div>
                    <div className="card-body">
                      <table
                        className="table table-bordered table-hover table-scroll"
                        id="tableLog1"
                        style={{
                          fontSize: "12px",
                          fontWeight: "semiBold",
                          display: "block",
                          overflow: "scroll",
                          height: "420px",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#437998",
                            color: "white",
                            textAlign: "center",
                            top: "0",
                            position: "sticky",
                            fontWeight: "reguler",
                          }}
                        >
                          <tr className="text-center">
                            <th width="1%">NO</th>
                            <th width="20%">USERNAME</th>
                            <th width="20%">JABATAN</th>
                            <th
                              width="10%"
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              AKTIVITAS
                            </th>
                            <th
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              TIMESTAMP
                            </th>
                          </tr>
                        </thead>

                        <tbody style={{ textAlign: "right" }}>
                          {dataLog?.map((list, index) => {
                            return (
                              <tr
                                key={index}
                                style={{
                                  textAlign: "center",
                                  fontSize: "13px",
                                }}
                              >
                                <td width={"1%"}>{index + 1}.</td>
                                <td>{list.username}</td>
                                <td>{list.rank_name}</td>
                                <td>{list.activity}</td>
                                <td>{list.log_time}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
}

export default ModalUserLog;
