function LoadSpin() {
  return (
    <div className="min-h-screen flex items-center justify-center">
      {/* Loading screen */}
      <div className="fixed top-0 left-0 w-full h-full bg-white opacity-80 z-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-24 w-24 border-t-8 border-redBrave"></div>
      </div>
    </div>
  );
}

export default LoadSpin;
