import { useEffect, useState } from "react";

function ParentHome() {
  const storedValue = localStorage.getItem("division_id");
  const color = localStorage.getItem("color");
  const items = storedValue.split(",");
  const colors = color.split(",");
  const combinedArray = colors.map((color, index) => ({
    color: color,
    id: items[index],
  }));

  const DashboardMain = (divisiId) => {
    window.location = `/dashboard/${divisiId}`;
  };
  return (
    <div className="bg-gray-200 flex justify-center items-center h-screen ">
      <div className="w-full mx-3 lg:w-1/4 md:w-1/2">
        <div className="bg-white mx-auto mb-20 px-6 py-6   border-2 rounded-xl">
          <div className="flex flex-col lg:flex-row">
            {combinedArray?.map((data, index) => {
              return (
                <button
                  key={index}
                  className={`w-full lg:w-1/2 my-2 mx-2 rounded-lg border-none border-slate-800 bg-slate-500`}
                  onClick={() => DashboardMain(data.id)}
                >
                  <h5 className="text-center font-bold px-2 py-6 text-white">
                    {data.color}
                  </h5>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParentHome;
