import React, { useState, useEffect, useRef } from "react";
import {
  GetDetailSSTO,
  GetNextTable,
  GetNextTable2,
  GetNextTable3,
} from "../../service/ratio/RatioService";
import Swal from "sweetalert2";
import Full from "../../load/Loading";
import "../../App.css";
import numeral from "numeral";

function ModalDetailSTO({
  onCloseModal,
  Group_Id,
  Group_Name,
  priode,
  rank,
  ranks,
  Id_type,
  idTeam,
  color,
}) {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const onCloseModalProperty = () => {
    onCloseModal();
  };

  const [dataRatioChart, setDataRatioChart] = useState({});
  const [dataNextData, setDataNextData] = useState({});
  const [dataNextData2, setDataNextData2] = useState({});
  const [dataNextData3, setDataNextData3] = useState({});
  const [nameId, setNameId] = useState("");
  const [nameId2, setNameId2] = useState("");
  const [nameId3, setNameId3] = useState("");

  const dataRatio = [];
  for (const key in dataRatioChart) {
    if (Object.hasOwnProperty.call(dataRatioChart, key)) {
      const element = dataRatioChart[key];
      dataRatio.push(element);
    }
  }
  const dataNext = [];
  for (const key in dataNextData) {
    if (Object.hasOwnProperty.call(dataNextData, key)) {
      const element = dataNextData[key];
      dataNext.push(element);
    }
  }
  const dataNext2 = [];
  for (const key in dataNextData2) {
    if (Object.hasOwnProperty.call(dataNextData2, key)) {
      const element = dataNextData2[key];
      dataNext2.push(element);
    }
  }
  const dataNext3 = [];
  for (const key in dataNextData3) {
    if (Object.hasOwnProperty.call(dataNextData3, key)) {
      const element = dataNextData3[key];
      dataNext3.push(element);
    }
  }

  const sortedData = dataRatio.sort((a, b) => b.m - a.m);
  const sortedDataNext = dataNext.sort((a, b) => b.m - a.m);
  const sortedDataNext2 = dataNext2.sort((a, b) => b.m - a.m);
  const sortedDataNext3 = dataNext3.sort((a, b) => b.m - a.m);

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const [growthTotal, setGrowthTotal] = useState("");
  const [growthYtdTotal, setGrowthYtdTotal] = useState("");

  const [growthTotal1, setGrowthTotal1] = useState("");
  const [growthYtdTotal1, setGrowthYtdTotal1] = useState("");

  const [growthTotal2, setGrowthTotal2] = useState("");
  const [growthYtdTotal2, setGrowthYtdTotal2] = useState("");

  const [growthTotal3, setGrowthTotal3] = useState("");
  const [growthYtdTotal3, setGrowthYtdTotal3] = useState("");

  useEffect(() => {
    showLoader();
    const payload = {
      id_type: Id_type,
      rank: rank ? rank : ranks,
      group_id: Group_Id,
      periode: priode,
      team_id: idTeam ? idTeam : "",
    };
    GetDetailSSTO(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataRatioChart(res.data.data);
          setGrowthTotal(res.data.growth_total);
          setGrowthYtdTotal(res.data.growth_ytd_total);
          initializeDataTables();
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  }, [Group_Id]);

  const NextTable = (id_team, name_id, id_next) => {
    setShow2(false);
    setShow3(false);
    setNameId(name_id);
    showLoader();
    const payload = {
      id_type: id_next,
      rank: rank ? rank : ranks,
      group_id: Group_Id,
      periode: priode,
      team_id: id_team,
    };
    GetNextTable(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataNextData(res.data.data);
          setGrowthTotal1(res.data.growth_total);
          setGrowthYtdTotal1(res.data.growth_ytd_total);
          initializeDataTables();
          setShow1(true);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const NextTable2 = (id_team2, name_id2, id_next2) => {
    setShow3(false);
    setNameId2(name_id2);
    showLoader();
    const payload = {
      id_type: id_next2,
      rank: rank ? rank : ranks,
      group_id: Group_Id,
      periode: priode,
      team_id: id_team2,
    };
    GetNextTable2(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataNextData2(res.data.data);
          setGrowthTotal2(res.data.growth_total);
          setGrowthYtdTotal2(res.data.growth_ytd_total);
          initializeDataTables();
          setShow2(true);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const NextTable3 = (id_team3, name_id3, id_next3) => {
    setNameId3(name_id3);
    showLoader();
    const payload = {
      id_type: id_next3,
      rank: rank ? rank : ranks,
      group_id: Group_Id,
      periode: priode,
      team_id: id_team3,
    };
    GetNextTable3(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataNextData3(res.data.data);
          setGrowthTotal3(res.data.growth_total);
          setGrowthYtdTotal3(res.data.growth_ytd_total);
          initializeDataTables();
          setShow3(true);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const formatToRupiah = (value) => {
    return numeral(value).format("0,0"); // Format angka dengan koma sebagai pemisah ribuan
  };

  // Inisialisasi variabel total
  let totalM3UnitChanel = 0;
  let totalM2UnitChanel = 0;
  let totalM1UnitChanel = 0;
  let totalMUnitChanel = 0;
  let totalM3Chanel = 0;
  let totalM2Chanel = 0;
  let totalM1Chanel = 0;
  let totalMChanel = 0;

  // Map dataChanel dan tambahkan nilai ke total
  dataRatio.forEach((list) => {
    totalM3UnitChanel += parseInt(list.m_3_unit);
    totalM2UnitChanel += parseInt(list.m_2_unit);
    totalM1UnitChanel += parseInt(list.m_1_unit);
    totalMUnitChanel += parseInt(list.m_unit);
    totalM3Chanel += parseInt(list.m_3);
    totalM2Chanel += parseInt(list.m_2);
    totalM1Chanel += parseInt(list.m_1);
    totalMChanel += parseInt(list.m);
  });
  // Inisialisasi variabel total
  let totalM3UnitChanelNext = 0;
  let totalM2UnitChanelNext = 0;
  let totalM1UnitChanelNext = 0;
  let totalMUnitChanelNext = 0;
  let totalM3ChanelNext = 0;
  let totalM2ChanelNext = 0;
  let totalM1ChanelNext = 0;
  let totalMChanelNext = 0;

  // Map dataChanel dan tambahkan nilai ke total
  dataNext.forEach((list) => {
    totalM3UnitChanelNext += parseInt(list.m_3_unit);
    totalM2UnitChanelNext += parseInt(list.m_2_unit);
    totalM1UnitChanelNext += parseInt(list.m_1_unit);
    totalMUnitChanelNext += parseInt(list.m_unit);
    totalM3ChanelNext += parseInt(list.m_3);
    totalM2ChanelNext += parseInt(list.m_2);
    totalM1ChanelNext += parseInt(list.m_1);
    totalMChanelNext += parseInt(list.m);
  });

  // Inisialisasi variabel total
  let totalM3UnitChanelNext2 = 0;
  let totalM2UnitChanelNext2 = 0;
  let totalM1UnitChanelNext2 = 0;
  let totalMUnitChanelNext2 = 0;
  let totalM3ChanelNext2 = 0;
  let totalM2ChanelNext2 = 0;
  let totalM1ChanelNext2 = 0;
  let totalMChanelNext2 = 0;

  // Map dataChanel dan tambahkan nilai ke total
  dataNext2.forEach((list) => {
    totalM3UnitChanelNext2 += parseInt(list.m_3_unit);
    totalM2UnitChanelNext2 += parseInt(list.m_2_unit);
    totalM1UnitChanelNext2 += parseInt(list.m_1_unit);
    totalMUnitChanelNext2 += parseInt(list.m_unit);
    totalM3ChanelNext2 += parseInt(list.m_3);
    totalM2ChanelNext2 += parseInt(list.m_2);
    totalM1ChanelNext2 += parseInt(list.m_1);
    totalMChanelNext2 += parseInt(list.m);
  });

  // Inisialisasi variabel total
  let totalM3UnitChanelNext3 = 0;
  let totalM2UnitChanelNext3 = 0;
  let totalM1UnitChanelNext3 = 0;
  let totalMUnitChanelNext3 = 0;
  let totalM3ChanelNext3 = 0;
  let totalM2ChanelNext3 = 0;
  let totalM1ChanelNext3 = 0;
  let totalMChanelNext3 = 0;

  // Map dataChanel dan tambahkan nilai ke total
  dataNext3.forEach((list) => {
    totalM3UnitChanelNext3 += parseInt(list.m_3_unit);
    totalM2UnitChanelNext3 += parseInt(list.m_2_unit);
    totalM1UnitChanelNext3 += parseInt(list.m_1_unit);
    totalMUnitChanelNext3 += parseInt(list.m_unit);
    totalM3ChanelNext3 += parseInt(list.m_3);
    totalM2ChanelNext3 += parseInt(list.m_2);
    totalM1ChanelNext3 += parseInt(list.m_1);
    totalMChanelNext3 += parseInt(list.m);
  });

  const initializeDataTables = () => {
    // Initialize DataTables library here after data is fetched and rendered
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/content.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const script = document.createElement("script");
      script.src = `${process.env.PUBLIC_URL}/js/content.js`;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [dataRatioChart]);

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-60 z-30 max-h-[100vh]"></div>
      <div
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-40 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className=" pb-12  mx-[3%] my-[2%]  max-w-8xl">
          {/* Modal content */}
          <div className=" bg-white rounded-lg shadow  ">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <button
                onClick={onCloseModalProperty}
                type="button"
                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xl w-12 h-12 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="overflow-y-auto overflow-x-auto max-h-[800px] ">
              <div className="p-4 md:p-5 relative">
                <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                  <h3 className="font-semibold text-lg mx-2 ">
                    Detail Data{" "}
                    <span className="text-redBrave">{Group_Name}</span>
                  </h3>
                  <div>
                    <div className="card-body">
                      <table
                        className="table table-bordered table-hover table-scroll"
                        id="example90"
                        style={{
                          fontSize: "12px",
                          fontWeight: "semiBold",
                          display: "block",
                          overflow: "scroll",
                          height: "420px",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#DD8E6B",
                            color: "white",
                            textAlign: "center",
                            top: "0",
                            zIndex: "10",
                            position: "sticky",
                            fontWeight: "reguler",
                          }}
                        >
                          <tr className="text-center">
                            <th
                              rowSpan={"2"}
                              style={{
                                left: "0",
                                zIndex: "5",
                                position: "sticky",
                                verticalAlignAlign: "middle",
                                backgroundColor: "#DD8E6B",
                              }}
                            >
                              NO
                            </th>
                            <th
                              rowSpan={"2"}
                              style={{
                                left: "60px",
                                zIndex: "10",
                                position: "sticky",
                                verticalAlignAlign: "middle",
                                backgroundColor: "#DD8E6B",
                              }}
                            >
                              NAMA
                            </th>
                            <th colSpan={"4"}>UNIT</th>
                            <th colSpan={"4"}>VALUE</th>
                            <th rowSpan={"2"}>KONTRIBUSI</th>
                            <th rowSpan={"2"}>GROWTH YTD</th>
                            <th rowSpan={"2"}>GROWTH</th>
                          </tr>
                          <tr className="text-center">
                            <th>M-3</th>
                            <th>M-2</th>
                            <th>M-1</th>
                            <th>M</th>
                            <th>M-3</th>
                            <th>M-2</th>
                            <th>M-1</th>
                            <th>M</th>
                          </tr>
                        </thead>

                        <tbody style={{ textAlign: "right" }}>
                          {sortedData.map((list, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    textAlign: "center",
                                    left: "0px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                  width={"1%"}
                                >
                                  {index + 1}.
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    fontStyle: "italic",
                                    color: "#3b82f6",
                                    left: "60px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  <span
                                    onClick={() =>
                                      NextTable(
                                        list.team_id,
                                        list.team_name,
                                        list.id_type_next
                                      )
                                    }
                                  >
                                    {list.team_name}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span
                                    className={`${
                                      parseFloat(list.m_3_unit) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_3_unit)}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span
                                    className={`${
                                      parseFloat(list.m_2_unit) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_2_unit)}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span
                                    className={`${
                                      parseFloat(list.m_1_unit) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_1_unit)}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span
                                    className={`${
                                      parseFloat(list.m_unit) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_unit)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(list.m_3) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_3)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(list.m_2) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_2)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(list.m_1) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_1)}
                                  </span>
                                </td>
                                <td className="text-green-500">
                                  {" "}
                                  <span
                                    className={`${
                                      parseFloat(list.m) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m)}
                                  </span>
                                </td>
                                <td className="text-center">
                                  {" "}
                                  {list.kontribusi}%
                                </td>
                                <td className="text-center">
                                  {" "}
                                  <span
                                    className={`${
                                      parseFloat(list.growth_ytd) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {list.growth_ytd}%
                                  </span>
                                </td>
                                <td className="text-center">
                                  {" "}
                                  <span
                                    className={`${
                                      parseFloat(list.growth) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {list.growth}%
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot
                          style={{ textAlign: "right" }}
                          className="sticky bottom-0 z-10"
                        >
                          <tr className="bg-white border border-slate-800 font-bold">
                            <td
                              colSpan={"2"}
                              style={{
                                textAlign: "center",
                                left: "0px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              TOTAL
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {" "}
                              <span
                                className={`${
                                  parseFloat(totalM3UnitChanel) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalM3UnitChanel)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {" "}
                              <span
                                className={`${
                                  parseFloat(totalM2UnitChanel) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalM2UnitChanel)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(totalM1UnitChanel) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalM1UnitChanel)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(totalMUnitChanel) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalMUnitChanel)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(totalM3Chanel) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalM3Chanel)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(totalM2Chanel) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalM2Chanel)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(totalM1Chanel) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalM1Chanel)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(totalMChanel) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalMChanel)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>100 %</td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(growthYtdTotal) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {growthYtdTotal} %
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(growthTotal) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {growthTotal} %
                              </span>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>

                {show1 ? (
                  <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                    <h3 className="font-semibold text-lg mx-2 ">
                      Detail Data{" "}
                      <span className="text-redBrave">{nameId}</span>
                    </h3>
                    <div>
                      <div className="card-body">
                        <table
                          className="table table-bordered table-hover table-scroll"
                          id="example91"
                          style={{
                            fontSize: "12px",
                            fontWeight: "semiBold",
                            display: "block",
                            overflow: "scroll",
                            height: "420px",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <thead
                            style={{
                              backgroundColor: "#DD8E6B",
                              color: "white",
                              textAlign: "center",
                              top: "0",
                              zIndex: "10",
                              position: "sticky",
                              fontWeight: "reguler",
                            }}
                          >
                            <tr className="text-center">
                              <th
                                rowSpan={"2"}
                                style={{
                                  left: "0",
                                  zIndex: "5",
                                  position: "sticky",
                                  verticalAlignAlign: "middle",
                                  backgroundColor: "#DD8E6B",
                                }}
                              >
                                NO
                              </th>
                              <th
                                rowSpan={"2"}
                                style={{
                                  left: "60px",
                                  zIndex: "10",
                                  position: "sticky",
                                  verticalAlignAlign: "middle",
                                  backgroundColor: "#DD8E6B",
                                }}
                              >
                                NAMA
                              </th>
                              <th colSpan={"4"}>UNIT</th>
                              <th colSpan={"4"}>VALUE</th>
                              <th rowSpan={"2"}>KONTRIBUSI</th>
                              <th rowSpan={"2"}>GROWTH YTD</th>
                              <th rowSpan={"2"}>GROWTH</th>
                            </tr>
                            <tr className="text-center">
                              <th>M-3</th>
                              <th>M-2</th>
                              <th>M-1</th>
                              <th>M</th>
                              <th>M-3</th>
                              <th>M-2</th>
                              <th>M-1</th>
                              <th>M</th>
                            </tr>
                          </thead>

                          <tbody style={{ textAlign: "right" }}>
                            {sortedDataNext.map((list, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      left: "0px",
                                      zIndex: "5",
                                      position: "sticky",
                                      backgroundColor: "#ffffff",
                                    }}
                                    width={"1%"}
                                  >
                                    {index + 1}.
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "left",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                      fontStyle: "italic",
                                      color: "#3b82f6",
                                      left: "60px",
                                      zIndex: "5",
                                      position: "sticky",
                                      backgroundColor: "#ffffff",
                                    }}
                                  >
                                    <span
                                      onClick={() =>
                                        NextTable2(
                                          list.team_id,
                                          list.team_name,
                                          list.id_type_next
                                        )
                                      }
                                    >
                                      {list.team_name}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_3_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_3_unit)}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_2_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_2_unit)}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_1_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_1_unit)}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_unit)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`${
                                        parseFloat(list.m_3) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_3)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`${
                                        parseFloat(list.m_2) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_2)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`${
                                        parseFloat(list.m_1) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_1)}
                                    </span>
                                  </td>
                                  <td className="text-green-500">
                                    {" "}
                                    <span
                                      className={`${
                                        parseFloat(list.m) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m)}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    {list.kontribusi}%
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    <span
                                      className={`${
                                        parseFloat(list.growth_ytd) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {list.growth_ytd}%
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    <span
                                      className={`${
                                        parseFloat(list.growth) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {list.growth}%
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot
                            style={{ textAlign: "right" }}
                            className="sticky bottom-0 z-10"
                          >
                            <tr className="bg-white border border-slate-800 font-bold">
                              <td
                                colSpan={"2"}
                                style={{
                                  textAlign: "center",
                                  left: "0px",
                                  zIndex: "5",
                                  position: "sticky",
                                  backgroundColor: "#ffffff",
                                }}
                              >
                                TOTAL
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {" "}
                                <span
                                  className={`${
                                    parseFloat(totalM3UnitChanelNext) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM3UnitChanelNext)}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {" "}
                                <span
                                  className={`${
                                    parseFloat(totalM2UnitChanelNext) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM2UnitChanelNext)}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(totalM1UnitChanelNext) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM1UnitChanelNext)}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(totalMUnitChanelNext) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalMUnitChanelNext)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalM3Chanel) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM3ChanelNext)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalM2ChanelNext) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM2ChanelNext)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalM1ChanelNext) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM1ChanelNext)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalMChanelNext) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalMChanelNext)}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>100 %</td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(growthYtdTotal1) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {growthYtdTotal1} %
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(growthTotal1) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {growthTotal1} %
                                </span>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : null}

                {show2 ? (
                  <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                    <h3 className="font-semibold text-lg mx-2 ">
                      Detail Data{" "}
                      <span className="text-redBrave">{nameId2}</span>
                    </h3>
                    <div>
                      <div className="card-body">
                        <table
                          className="table table-bordered table-hover table-scroll"
                          id="example92"
                          style={{
                            fontSize: "12px",
                            fontWeight: "semiBold",
                            display: "block",
                            overflow: "scroll",
                            height: "420px",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <thead
                            style={{
                              backgroundColor: "#DD8E6B",
                              color: "white",
                              textAlign: "center",
                              top: "0",
                              zIndex: "10",
                              position: "sticky",
                              fontWeight: "reguler",
                            }}
                          >
                            <tr className="text-center">
                              <th
                                rowSpan={"2"}
                                style={{
                                  left: "0",
                                  zIndex: "5",
                                  position: "sticky",
                                  verticalAlignAlign: "middle",
                                  backgroundColor: "#DD8E6B",
                                }}
                              >
                                NO
                              </th>
                              <th
                                rowSpan={"2"}
                                style={{
                                  left: "60px",
                                  zIndex: "10",
                                  position: "sticky",
                                  verticalAlignAlign: "middle",
                                  backgroundColor: "#DD8E6B",
                                }}
                              >
                                NAMA
                              </th>
                              <th colSpan={"4"}>UNIT</th>
                              <th colSpan={"4"}>VALUE</th>
                              <th rowSpan={"2"}>KONTRIBUSI</th>
                              <th rowSpan={"2"}>GROWTH YTD</th>
                              <th rowSpan={"2"}>GROWTH</th>
                            </tr>
                            <tr className="text-center">
                              <th>M-3</th>
                              <th>M-2</th>
                              <th>M-1</th>
                              <th>M</th>
                              <th>M-3</th>
                              <th>M-2</th>
                              <th>M-1</th>
                              <th>M</th>
                            </tr>
                          </thead>

                          <tbody style={{ textAlign: "right" }}>
                            {sortedDataNext2.map((list, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      left: "0px",
                                      zIndex: "5",
                                      position: "sticky",
                                      backgroundColor: "#ffffff",
                                    }}
                                    width={"1%"}
                                  >
                                    {index + 1}.
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "left",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                      fontStyle: "italic",
                                      color: "#3b82f6",
                                      left: "60px",
                                      zIndex: "5",
                                      position: "sticky",
                                      backgroundColor: "#ffffff",
                                    }}
                                  >
                                    <span
                                      onClick={() =>
                                        NextTable3(
                                          list.team_id,
                                          list.team_name,
                                          list.id_type_next
                                        )
                                      }
                                    >
                                      {list.team_name}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_3_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_3_unit)}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_2_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_2_unit)}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_1_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_1_unit)}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_unit)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`${
                                        parseFloat(list.m_3) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_3)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`${
                                        parseFloat(list.m_2) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_2)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`${
                                        parseFloat(list.m_1) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_1)}
                                    </span>
                                  </td>
                                  <td className="text-green-500">
                                    {" "}
                                    <span
                                      className={`${
                                        parseFloat(list.m) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m)}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    {list.kontribusi}%
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    <span
                                      className={`${
                                        parseFloat(list.growth_ytd) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {list.growth_ytd}%
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    <span
                                      className={`${
                                        parseFloat(list.growth) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {list.growth}%
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot
                            style={{ textAlign: "right" }}
                            className="sticky bottom-0 z-10"
                          >
                            <tr className="bg-white border border-slate-800 font-bold">
                              <td
                                colSpan={"2"}
                                style={{
                                  textAlign: "center",
                                  left: "0px",
                                  zIndex: "5",
                                  position: "sticky",
                                  backgroundColor: "#ffffff",
                                }}
                              >
                                TOTAL
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {" "}
                                <span
                                  className={`${
                                    parseFloat(totalM3UnitChanelNext2) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM3UnitChanelNext2)}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {" "}
                                <span
                                  className={`${
                                    parseFloat(totalM2UnitChanelNext2) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM2UnitChanelNext2)}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(totalM1UnitChanelNext2) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM1UnitChanelNext2)}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(totalMUnitChanelNext2) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalMUnitChanelNext2)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalM3ChanelNext2) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM3ChanelNext2)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalM2ChanelNext2) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM2ChanelNext2)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalM1ChanelNext2) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM1ChanelNext2)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalMChanelNext2) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalMChanelNext2)}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>100 %</td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(growthYtdTotal2) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {growthYtdTotal2} %
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(growthTotal2) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {growthTotal2} %
                                </span>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : null}

                {show3 ? (
                  <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                    <h3 className="font-semibold text-lg mx-2 ">
                      Detail Data{" "}
                      <span className="text-redBrave">{nameId3}</span>
                    </h3>
                    <div>
                      <div className="card-body">
                        <table
                          className="table table-bordered table-hover table-scroll"
                          id="example93"
                          style={{
                            fontSize: "12px",
                            fontWeight: "semiBold",
                            display: "block",
                            overflow: "scroll",
                            height: "420px",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <thead
                            style={{
                              backgroundColor: "#DD8E6B",
                              color: "white",
                              textAlign: "center",
                              top: "0",
                              zIndex: "10",
                              position: "sticky",
                              fontWeight: "reguler",
                            }}
                          >
                            <tr className="text-center">
                              <th
                                rowSpan={"2"}
                                style={{
                                  left: "0",
                                  zIndex: "5",
                                  position: "sticky",
                                  verticalAlignAlign: "middle",
                                  backgroundColor: "#DD8E6B",
                                }}
                              >
                                NO
                              </th>
                              <th
                                rowSpan={"2"}
                                style={{
                                  left: "60px",
                                  zIndex: "10",
                                  position: "sticky",
                                  verticalAlignAlign: "middle",
                                  backgroundColor: "#DD8E6B",
                                }}
                              >
                                NAMA
                              </th>
                              <th colSpan={"4"}>UNIT</th>
                              <th colSpan={"4"}>VALUE</th>
                              <th rowSpan={"2"}>KONTRIBUSI</th>
                              <th rowSpan={"2"}>GROWTH YTD</th>
                              <th rowSpan={"2"}>GROWTH</th>
                            </tr>
                            <tr className="text-center">
                              <th>M-3</th>
                              <th>M-2</th>
                              <th>M-1</th>
                              <th>M</th>
                              <th>M-3</th>
                              <th>M-2</th>
                              <th>M-1</th>
                              <th>M</th>
                            </tr>
                          </thead>

                          <tbody style={{ textAlign: "right" }}>
                            {sortedDataNext3.map((list, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      left: "0px",
                                      zIndex: "5",
                                      position: "sticky",
                                      backgroundColor: "#ffffff",
                                    }}
                                    width={"1%"}
                                  >
                                    {index + 1}.
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "left",
                                      left: "60px",
                                      zIndex: "5",
                                      position: "sticky",
                                      backgroundColor: "#ffffff",
                                    }}
                                  >
                                    {list.team_name}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_3_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_3_unit)}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_2_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_2_unit)}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_1_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_1_unit)}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_unit)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`${
                                        parseFloat(list.m_3) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_3)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`${
                                        parseFloat(list.m_2) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_2)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`${
                                        parseFloat(list.m_1) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_1)}
                                    </span>
                                  </td>
                                  <td className="text-green-500">
                                    {" "}
                                    <span
                                      className={`${
                                        parseFloat(list.m) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m)}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    {list.kontribusi}%
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    <span
                                      className={`${
                                        parseFloat(list.growth_ytd) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {list.growth_ytd}%
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    <span
                                      className={`${
                                        parseFloat(list.growth) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {list.growth}%
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot
                            style={{ textAlign: "right" }}
                            className="sticky bottom-0 z-10"
                          >
                            <tr className="bg-white border border-slate-800 font-bold">
                              <td
                                colSpan={"2"}
                                style={{
                                  textAlign: "center",
                                  left: "0px",
                                  zIndex: "5",
                                  position: "sticky",
                                  backgroundColor: "#ffffff",
                                }}
                              >
                                TOTAL
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {" "}
                                <span
                                  className={`${
                                    parseFloat(totalM3UnitChanelNext3) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM3UnitChanelNext3)}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {" "}
                                <span
                                  className={`${
                                    parseFloat(totalM2UnitChanelNext3) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM2UnitChanelNext3)}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(totalM1UnitChanelNext3) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM1UnitChanelNext3)}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(totalMUnitChanelNext3) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalMUnitChanelNext3)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalM3ChanelNext3) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM3ChanelNext3)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalM2ChanelNext3) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM2ChanelNext3)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalM1ChanelNext3) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM1ChanelNext3)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalMChanelNext3) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalMChanelNext3)}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>100 %</td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(growthYtdTotal3) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {growthYtdTotal3} %
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(growthTotal3) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {growthTotal3} %
                                </span>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
}

export default ModalDetailSTO;
