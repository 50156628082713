import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  levelActive: "",
};

export const levelSlice = createSlice({
  name: "level",
  initialState,
  reducers: {
    setLevelSlice: (state, action) => {
      state.levelActive = action.payload.level;
    },
  },
});

export const { setLevelSlice } = levelSlice.actions;

export const levelReducer = levelSlice.reducer;
