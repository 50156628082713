import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";

function Navbar() {
  const [hasShadow, setHasShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setHasShadow(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const location = useLocation();

  const logout = async () => {
    Swal.fire({
      title: "Apakah Anda Yakin Keluar?",
      showDenyButton: true,
      icon: "info",
      confirmButtonText: "Ya, Keluar",
      denyButtonText: `Batal`,
      customClass: {
        confirmButton: "btn btn-success",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location = "/";
        localStorage.removeItem("token");
        localStorage.removeItem("rank_name");
        localStorage.removeItem("division_id");
        localStorage.removeItem("team_id");
        localStorage.removeItem("id_type");
        localStorage.removeItem("rank");
        localStorage.removeItem("color");
        localStorage.removeItem("is_manager");
      }
    });
  };

  return (
    <>
      {/* Navbar */}
      <nav
        className={` bg-redBrave fixed top-0 w-full z-30 ${
          hasShadow ? "shadow" : ""
        }`}
      >
        <div className="flex justify-end lg:justify-end px-3">
          <div className="w-full lg:w-[53%] px-2">
            <div className="py-2 flex justify-between items-center">
              <h3 className="text-white font-bold text-lg"></h3>

              {/* Responsive menu */}
              <div className={`lg:flex`}>
                <ul className="lg:flex space-x-4">
                  <li
                    onClick={logout}
                    className="cursor-pointer text-[12px] font-reguler lg:text-[15px] text-white fill-current hover:text-white"
                  >
                    Logout
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* /.navbar */}
    </>
  );
}

export default Navbar;
