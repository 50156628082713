import React, { useState, useEffect, useRef } from "react";
import numeral from "numeral";
import { getDataProduk } from "../../service/ratio/RatioService";
import Full from "../../load/Loading";
import Swal from "sweetalert2";
import StockSubdist from "./StokSubdist";

function Stok({
  dataSubdist,
  rank,
  NilaiStok,
  AvgSales,
  DoiValue,
  colorPopup,
}) {
  useEffect(() => {
    setDataCabang([]);
  }, [dataSubdist]);

  //loading
  const [loader, showLoader, hideLoader] = Full();

  const sortedDataSubdist = dataSubdist.sort(
    (a, b) => b.ValueStock - a.ValueStock
  );

  const [dataCabang, setDataCabang] = useState([]);
  const [namaCabang, setNamaCabang] = useState("");
  const [sellerId, setSellerId] = useState("");
  const [NilaiStokCabang, setNilaiStokCabang] = useState("");
  const [AvgSalesCabang, setAvgSalesCabang] = useState("");
  const [DoiValueCabang, setDoiValueCabang] = useState("");
  const [StokDate, setStokDate] = useState("");

  //konversi nilai
  const formatToRupiah = (value) => {
    return numeral(value).format("0,0"); // Format angka dengan koma sebagai pemisah ribuan
  };

  //ketika pilih unit stok
  const handleCabang = (nameCabang, nextTable, subdistId) => {
    const payload = {
      rank: rank,
      subdist_code: subdistId,
      search_name: nextTable,
      cabang: "",
      product_code: "",
    };
    setNamaCabang(nameCabang);
    setSellerId(subdistId);
    showLoader();
    getDataProduk(payload)
      .then((res) => {
        if ((res.data.success = "true")) {
          setDataCabang(res.data.data);
          setNilaiStokCabang(res.data.subtotal_totalValue);
          setAvgSalesCabang(res.data.subtotal_avgValue);
          setDoiValueCabang(res.data.subtotal_doiValue);
          setStokDate(res.data.stock_date);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  useEffect(() => {
    initializeDataTables();
  }, [sortedDataSubdist]);

  const initializeDataTables = () => {
    // Initialize DataTables library here after data is fetched and rendered
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/content.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  return (
    <>
      {dataSubdist.length > 0 ? (
        <section className="">
          <div style={{ marginTop: "30px" }}>
            <div className="border-[1px] border-[#437998] rounded-xl p-2">
              <h3 className="text-regular text-[14px] lg:text-[18px] md:text-[18px] mb-2 text-center">
                <span className="font-bold uppercase">
                  STOK ( {colorPopup} )
                </span>
              </h3>
              <div className="card-body">
                <table
                  className="table table-bordered table-hover table-scroll mb-4"
                  id="example510"
                  style={{
                    fontSize: "12px",
                    fontWeight: "semiBold",
                    display: "block",
                    overflow: "scroll",
                    height: "230px",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: "#73605F",
                      color: "white",
                      textAlign: "center",
                      top: "0",
                      zIndex: "10",
                      position: "sticky",
                      fontWeight: "reguler",
                    }}
                  >
                    <tr>
                      <th
                        style={{
                          left: "0",
                          zIndex: "5",
                          position: "sticky",
                          verticalAlign: "middle",
                          backgroundColor: "#73605F",
                        }}
                      >
                        NO
                      </th>
                      <th
                        style={{
                          left: "60px",
                          zIndex: "10",
                          position: "sticky",
                          verticalAlign: "middle",
                          backgroundColor: "#73605F",
                        }}
                      >
                        STOK
                      </th>
                      <th
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        NILAI STOK
                      </th>
                      <th
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        NILAI RATA-RATA SALES
                      </th>
                      <th
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        DOI
                      </th>
                      <th
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        TANGGAL STOK
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "right" }}>
                    {sortedDataSubdist.map((list, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            textAlign: "center",
                            left: "0px",
                            zIndex: "5",
                            position: "sticky",
                            backgroundColor: "#ffffff",
                          }}
                          width={"1%"}
                        >
                          {index + 1}.
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            left: "40px",
                            zIndex: "5",
                            position: "sticky",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          {list.subdist_name}
                        </td>
                        <td
                          style={{
                            textAlign: "right ",
                            left: "60px",
                            zIndex: "5",
                            position: "sticky",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <span
                            className={`${
                              parseFloat(list.ValueStock) < 0
                                ? "text-red-500"
                                : "text-[#5077C4] underline cursor-pointer italic"
                            }`}
                            onClick={() =>
                              handleCabang(
                                list.subdist_name,
                                list.next,
                                list.subdist_id
                              )
                            }
                          >
                            {formatToRupiah(list.ValueStock)}
                          </span>
                        </td>

                        <td>
                          <span
                            className={`${
                              parseFloat(list.avgValue) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(list.avgValue)}
                          </span>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <span
                            className={`${
                              parseFloat(list.doiValue) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {list.doiValue !== null ? (
                              <span> {list.doiValue}</span>
                            ) : (
                              <span>&infin;</span>
                            )}
                          </span>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {list.stock_date}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="sticky bottom-0 z-10">
                    <tr className="bg-white border border-slate-800 font-bold">
                      <th colSpan={"2"} className="text-center">
                        TOTAL
                      </th>
                      <th className="text-right">
                        {" "}
                        <span
                          className={`${
                            parseFloat(NilaiStok) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {formatToRupiah(NilaiStok)}
                        </span>
                      </th>
                      <th className="text-right">
                        {" "}
                        <span
                          className={`${
                            parseFloat(AvgSales) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {formatToRupiah(AvgSales)}
                        </span>
                      </th>
                      <th className="text-center">
                        {" "}
                        <span
                          className={`${
                            parseFloat(DoiValue) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {DoiValue}
                        </span>
                      </th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <StockSubdist
            dataCabang={dataCabang}
            subdistId={sellerId}
            rank={rank}
            namaCabangs={namaCabang}
            NilaiStok={NilaiStokCabang}
            AvgSales={AvgSalesCabang}
            DoiValue={DoiValueCabang}
            StokDate={StokDate}
          />
        </section>
      ) : null}

      {loader}
    </>
  );
}

export default Stok;
