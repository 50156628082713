import React, { useState, useEffect } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { AuthLogin } from "../service/AuthService";
import Swal from "sweetalert2";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
const eye1 = <FontAwesomeIcon icon={faEyeSlash} />;

function Login() {
  const params = useParams();
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const hasToken = localStorage.getItem("token");

  const idDivisi = params.idDivisi;
  if (hasToken) {
    window.location = `/dashboard/${idDivisi}`;
  }

  //handle login
  const handleLogin = ({ email, password }) => {
    setLoading(true);
    AuthLogin(email, password)
      .then((res) => {
        if (res.data.success === true) {
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("rank_name", res.data.data.rank_name);
          localStorage.setItem("division_id", res.data.data.division_id);
          localStorage.setItem("team_id", res.data.data.team_id);
          localStorage.setItem("id_type", res.data.data.id_type);
          localStorage.setItem("color", res.data.data.color);
          localStorage.setItem("is_manager", res.data.data.is_manager);
          const storedValue = res.data.data.division_id;
          const items = storedValue.split(",");
          if (items.length <= 1) {
            window.location = `/dashboard/${storedValue}`;
          } else {
            window.location = "/home";
          }
        } else {
          setLoading(false);
          Swal.fire({
            title: "Cek Email dan Password!!",
            showConfirmButton: false,
            icon: "warning",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          title: err.message,
          showConfirmButton: false,
          icon: "warning",
        });
      });
  };

  return (
    <div className="bg-gray-200 flex justify-center items-center h-screen ">
      <div className="w-full mx-3 lg:w-1/4 md:w-1/2">
        <div className="bg-white mx-auto mb-20 px-6 py-6  lg:px-12 lg:py-16  border-2 rounded-xl">
          <form onSubmit={handleSubmit(handleLogin)}>
            <h4 className="font-medium  text-black text-l mb-6 lg:text-2xl md:text-2xl">
              Login
            </h4>
            <div className="mb-2 lg:mb-8 md:mb-8">
              <input
                className="font-reguler border-b-1 border-l-0 border-r-0 border-t-0 border-redBrave bg-white  w-full py-2 lg:py-3 md:py-3 px-3 text-gray-700 focus:ring-white focus:border-redBrave block"
                id="email"
                type="email"
                placeholder="Email"
                {...register("email", {
                  required: "Email Tidak Boleh Kosong",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                as={<span className="text-danger text-xs"></span>}
              />
            </div>

            <div className="mb-4 lg:mb-8 md:mb-8">
              <div className="relative">
                <input
                  className="font-reguler border-b-1 border-l-0 border-r-0 border-t-0 border-redBrave bg-white w-full py-2 lg:py-3 md:py-3 px-3 text-gray-700 focus:ring-white focus:border-redBrave block"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  {...register("password", {
                    required: "Password Tidak Boleh Kosong",
                  })}
                  placeholder="Password"
                />

                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  {showPassword ? (
                    <i
                      className="h-5 w-5 text-gray-500 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    >
                      {eye}
                    </i>
                  ) : (
                    <i
                      className="h-5 w-5 text-gray-500 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    >
                      {eye1}
                    </i>
                  )}
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name="password"
                as={<span className="text-danger text-xs"></span>}
              />
            </div>
            {!isLoading && (
              <button
                type="submit"
                className="bg-redBrave hover:opacity-70 text-white font-medium py-2 px-12 rounded-lg shadow-xl flex justify-center mx-auto"
              >
                Login
              </button>
            )}
            {isLoading && (
              <button className="bg-redBrave  text-white font-medium py-2 px-12 rounded-lg shadow-xl flex justify-center mx-auto disabled">
                Login
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
