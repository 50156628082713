import React, { useState, useEffect } from "react";
import {
  GetDetailSSTO,
  GetNextTable,
  GetNextTable2,
  GetNextTable3,
} from "../../service/ratio/RatioService";
import Swal from "sweetalert2";
import Full from "../../load/Loading";
import "../../App.css";
import Highcharts from "highcharts";

function ModalDetailSTO({
  onCloseModal,
  Search_Id,
  Search_Name,
  priode,
  rank,
  ranks,
  Id_type,
  idTeam,
  color,
}) {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const onCloseModalProperty = () => {
    onCloseModal();
  };

  const [dataRatioChart, setDataRatioChart] = useState({});

  const transformedData = Object.keys(dataRatioChart).map((key) => {
    const team = dataRatioChart[key];
    return {
      id: team.team_id,
      id_next: team.id_type_next,
      name: team.team_name,
      data: Object.values(team.line).map(Number),
    };
  });

  const getKeysAsArray = (data) => {
    // Initialize an empty array to store the keys
    let keysArray = [];

    // Iterate over each object in the JSON data
    Object.values(data).forEach((item) => {
      // If the 'line' property exists and is an object
      if (item.line && typeof item.line === "object") {
        // Extract the keys from the 'line' object and concatenate them with the keys array
        keysArray = keysArray.concat(Object.keys(item.line));
      }
    });

    // Return the unique keys array (remove duplicates)
    return [...new Set(keysArray)];
  };

  // Call the function and get the keys as an array
  const keysArray = getKeysAsArray(dataRatioChart);

  const [dataNextData, setDataNextData] = useState({});

  const transformedDataNext = Object.keys(dataNextData).map((key) => {
    const team = dataNextData[key];
    return {
      id: team.team_id,
      id_next: team.id_type_next,
      name: team.team_name,
      data: Object.values(team.line).map(Number),
    };
  });

  const getKeysAsArrayNext = (data) => {
    // Initialize an empty array to store the keys
    let keysArrayNext = [];

    // Iterate over each object in the JSON data
    Object.values(data).forEach((item) => {
      // If the 'line' property exists and is an object
      if (item.line && typeof item.line === "object") {
        // Extract the keys from the 'line' object and concatenate them with the keys array
        keysArrayNext = keysArrayNext.concat(Object.keys(item.line));
      }
    });

    // Return the unique keys array (remove duplicates)
    return [...new Set(keysArrayNext)];
  };

  // Call the function and get the keys as an array
  const keysArrayNext = getKeysAsArrayNext(dataNextData);

  const [dataNextData2, setDataNextData2] = useState({});

  const transformedDataNext2 = Object.keys(dataNextData2).map((key) => {
    const team = dataNextData2[key];
    return {
      id: team.team_id,
      id_next: team.id_type_next,
      name: team.team_name,
      data: Object.values(team.line).map(Number),
    };
  });

  const getKeysAsArrayNext2 = (data) => {
    // Initialize an empty array to store the keys
    let keysArrayNext2 = [];

    // Iterate over each object in the JSON data
    Object.values(data).forEach((item) => {
      // If the 'line' property exists and is an object
      if (item.line && typeof item.line === "object") {
        // Extract the keys from the 'line' object and concatenate them with the keys array
        keysArrayNext2 = keysArrayNext2.concat(Object.keys(item.line));
      }
    });

    // Return the unique keys array (remove duplicates)
    return [...new Set(keysArrayNext2)];
  };

  // Call the function and get the keys as an array
  const keysArrayNext2 = getKeysAsArrayNext2(dataNextData2);

  const [dataNextData3, setDataNextData3] = useState({});

  const transformedDataNext3 = Object.keys(dataNextData3).map((key) => {
    const team = dataNextData3[key];
    return {
      id: team.team_id,
      id_next: team.id_type_next,
      name: team.team_name,
      data: Object.values(team.line).map(Number),
    };
  });

  const getKeysAsArrayNext3 = (data) => {
    // Initialize an empty array to store the keys
    let keysArrayNext3 = [];

    // Iterate over each object in the JSON data
    Object.values(data).forEach((item) => {
      // If the 'line' property exists and is an object
      if (item.line && typeof item.line === "object") {
        // Extract the keys from the 'line' object and concatenate them with the keys array
        keysArrayNext3 = keysArrayNext3.concat(Object.keys(item.line));
      }
    });

    // Return the unique keys array (remove duplicates)
    return [...new Set(keysArrayNext3)];
  };

  // Call the function and get the keys as an array
  const keysArrayNext3 = getKeysAsArrayNext2(dataNextData3);

  const [nameId, setNameId] = useState("");
  const [nameId2, setNameId2] = useState("");
  const [nameId3, setNameId3] = useState("");

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  useEffect(() => {
    showLoader();
    const payload = {
      id_type: Id_type,
      rank: rank ? rank : ranks,
      search_id: Search_Id,
      group_id: "all",
      periode: priode,
      team_id: idTeam ? idTeam : "",
    };
    GetDetailSSTO(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataRatioChart(res.data.data);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  }, [Search_Id]);

  const NextChart = (id_team, name_id, id_next) => {
    setShow2(false);
    setShow3(false);
    setNameId(name_id);
    showLoader();
    const payload = {
      id_type: id_next,
      rank: rank ? rank : ranks,
      search_id: Search_Id,
      group_id: "all",
      periode: priode,
      team_id: id_team,
    };
    GetNextTable(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataNextData(res.data.data);
          setShow1(true);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const NextChart2 = (id_team2, name_id2, id_next2) => {
    setShow3(false);
    setNameId2(name_id2);
    showLoader();
    const payload = {
      id_type: id_next2,
      rank: rank ? rank : ranks,
      search_id: Search_Id,
      group_id: "all",
      periode: priode,
      team_id: id_team2,
    };
    GetNextTable2(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataNextData2(res.data.data);
          setShow2(true);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  };

  //showing pie chart
  useEffect(() => {
    Highcharts.chart("containerPie", {
      chart: {
        type: "line",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: keysArray,
        crosshair: true,
        accessibility: {
          description: "",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        valueSuffix: "",
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          events: {
            legendItemClick: function (event) {
              const series = this;
              setTimeout(() => {
                NextChart(
                  series.userOptions.id,
                  series.userOptions.name,
                  series.userOptions.id_next
                );
              }, 0);
              return false; // Disable hiding on legend click
            },
          },
        },
      },
      series: transformedData,
    });
  }, [transformedData]);

  useEffect(() => {
    Highcharts.chart("containerPieNext", {
      chart: {
        type: "line",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: keysArrayNext,
        crosshair: true,
        accessibility: {
          description: "",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        valueSuffix: "",
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          events: {
            legendItemClick: function (event) {
              const series = this;
              setTimeout(() => {
                NextChart2(
                  series.userOptions.id,
                  series.userOptions.name,
                  series.userOptions.id_next
                );
              }, 0);
              return false; // Disable hiding on legend click
            },
          },
        },
      },
      series: transformedDataNext,
    });
  }, [transformedDataNext]);

  useEffect(() => {
    Highcharts.chart("containerPieNext2", {
      chart: {
        type: "line",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: keysArrayNext2,
        crosshair: true,
        accessibility: {
          description: "",
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        valueSuffix: "",
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          events: {
            legendItemClick: function (event) {
              return false; // Disable hiding on legend click
            },
          },
        },
      },
      series: transformedDataNext2,
    });
  }, [transformedDataNext2]);

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-60 z-30 max-h-[100vh]"></div>

      <div
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-40 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className=" pb-12 mx-[3%] my-[2%] max-w-8xl">
          {/* Modal content */}
          <div className="bg-white rounded-lg shadow">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <button
                onClick={onCloseModalProperty}
                type="button"
                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xl w-12 h-12 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="overflow-y-auto overflow-x-auto max-h-[800px]">
              <div className="p-4 md:p-5 relative ">
                <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                  <h3 className="font-semibold text-lg mx-2 ">
                    Grafik Line Chart{" "}
                    <span className="text-redBrave">{Search_Name}</span>
                  </h3>
                  <div>
                    <div className="card-body">
                      <figure className="highcharts-figure">
                        <div id="containerPie" className="lg:py-6"></div>
                      </figure>
                    </div>
                  </div>
                </div>

                {show1 ? (
                  <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                    <h3 className="font-semibold text-lg mx-2 ">
                      Grafik Line Chart{" "}
                      <span className="text-redBrave">{nameId}</span>
                    </h3>
                    <div>
                      <div className="card-body">
                        <figure className="highcharts-figure">
                          <div id="containerPieNext" className="lg:py-6"></div>
                        </figure>
                      </div>
                    </div>
                  </div>
                ) : (
                  <figure
                    className="highcharts-figure"
                    style={{ display: "none" }}
                  >
                    <div id="containerPieNext" className="lg:py-6"></div>
                  </figure>
                )}

                {show2 ? (
                  <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                    <h3 className="font-semibold text-lg mx-2 ">
                      Grafik Line Chart{" "}
                      <span className="text-redBrave">{nameId2}</span>
                    </h3>
                    <div>
                      <div className="card-body">
                        <figure className="highcharts-figure">
                          <div id="containerPieNext2" className="lg:py-6"></div>
                        </figure>
                      </div>
                    </div>
                  </div>
                ) : (
                  <figure
                    className="highcharts-figure"
                    style={{ display: "none" }}
                  >
                    <div id="containerPieNext2" className="lg:py-6"></div>
                  </figure>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
}

export default ModalDetailSTO;
