import APIClient from "./APIClient";

export const AuthLogin = async (email, password) => {
  const param = {
    email_address: email,
    password: password,
  };
  const result = await APIClient.post("/login", param).then((res) => res);
  return result;
};
