import React from "react";
import DashboardHigh from "./DashboardHigh";
import DashboardLow from "./DashboardLow";

function DashboardHome() {
  //get localstrorage
  const divisi_id = localStorage.getItem("division_id");

  return <>{divisi_id == "0" ? <DashboardHigh /> : <DashboardLow />}</>;
}
export default DashboardHome;
