import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getDataRatioDetail } from "../../service/ratio/RatioService";
import Swal from "sweetalert2";
import Highcharts from "highcharts";

function DetailData() {
  const rank_name = localStorage.getItem("rank_name");
  const division_id = localStorage.getItem("division_id");
  const rank = localStorage.getItem("rank");
  const params = useParams();
  const type = params.type;
  const idArea = params.id;

  const [ratioDetail, setDataRatioDetail] = useState({});
  const data = Object.values(ratioDetail).map((region) => region.data[0]);
  const datas = { data };

  const handleClick = () => {
    window.location = `/dashboard`;
  };

  useEffect(() => {
    let payload;
    if (rank_name == "PD") {
      payload = {
        rank: rank,
        type: type,
        team_id_01: "",
        team_id_02: "",
        team_id_03: idArea,
        team_id_04: "",
        team_id: "",
        id_type: "",
      };
    } else {
      payload = {
        rank: division_id,
        type: type,
        team_id_01: "",
        team_id_02: "",
        team_id_03: idArea,
        team_id_04: "",
        team_id: "",
        id_type: "",
      };
    }

    getDataRatioDetail(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataRatioDetail(res.data.data);
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Data Tidak ditemukan",
          showConfirmButton: false,
          icon: "warning",
        });
      });
  }, [idArea]);

  useEffect(() => {
    let coloring;

    if (division_id == "14" || rank == "14") {
      coloring = ["red"];
    } else if (division_id == "27" || rank == "27") {
      coloring = ["blue"];
    } else if (division_id == "20" || rank == "20") {
      coloring = ["black"];
    }
    Highcharts.chart("container", {
      chart: {
        type: "pie",
      },
      title: {
        text: "data",
        align: "center",
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        series: {
          borderRadius: 5,
          cursor: "pointer",
          dataLabels: [
            {
              enabled: true,
              distance: 8,
              format: "{point.name}",
              style: {
                fontSize: "12px",
                textOutline: "none",
              },
            },
            {
              enabled: true,
              distance: "-30%",
              filter: {
                property: "percentage",
                operator: ">",
                value: 5,
              },
              format: "{point.y:.1f}%",
              style: {
                fontSize: "12px",
                textOutline: "none",
                color: "#ffffff",
              },
            },
          ],
          point: {
            events: {
              click: function () {
                //setTipeRatio(ratioBDU.name_data);
              },
            },
          },
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
      },
      colors: coloring,
      series: [datas],
    });
  }, [datas]);

  return (
    <div>
      <section className="px-6 mx-auto pb-12 pt-14">
        <h3 className="text-reguler text-[14px] lg:text-[20px] md:text-[18px] mb-2">
          Team : <span className="text-bold">{rank_name}</span>
        </h3>
        <div className="grid grid-cols-1 gap-4">
          <div className="w-full flex justify-between border-2 border-[#437998] rounded-xl p-1 mb-4">
            <div className="w-full lg:w-1/3 lg:my-6">
              <h3
                onClick={handleClick}
                className="text-medium text-[12px] lg:text-[16px] px-2 py-2"
              >
                Home
              </h3>
            </div>
            <div className="w-full lg:w-1/3 lg:my-6  border-none border-[#FFE4E4] rounded-xl bg-[#FFE4E4]">
              <h3 className="text-medium text-center text-[12px] lg:text-[16px] px-2 py-2">
                Update terakhir : 19 Februari 2024 - 12:00:00
              </h3>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="w-full">
            <div className="border-2 border-[#437998] rounded-xl p-1">
              <figure className="highcharts-figure">
                <div id="container" className="lg:py-12"></div>
              </figure>
            </div>
          </div>
          <div className="w-full">
            <div className="border-2 border-[#437998] rounded-xl p-1">
              <figure className="highcharts-figure">
                <div id="container2" className="lg:py-12"></div>
              </figure>
            </div>
          </div>
        </div>
        {/* <div id="chart"></div>
        <HighchartsReact highcharts={Highcharts} options={{ options }} /> */}
      </section>
    </div>
  );
}

export default DetailData;
