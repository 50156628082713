import React from "react";
import AppRouter from "./AppRouter";
import { Provider } from "react-redux";
import { store } from "./storage";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();
function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AppRouter></AppRouter>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
