import React, { useState, useEffect, useRef } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ParentHome from "./auth/ParentHome";
import Home from "./auth/Home";
import Login from "./auth/Login";
import ProtectedRoutes from "./router/ProtecedRouter";
import MainDashboard from "./components/dashboard/DashboardHome";
import Content from "./content";
import Detail from "./components/detailData/DetailData";
import SubDetail from "./components/detailData/SubDetailData";

const AppRouter = () => {
  return (
    <Routes history={BrowserRouter}>
      <Route element={<ProtectedRoutes />}>
        <Route element={<Home />}>
          <Route path="/dashboard/:id" element={<MainDashboard />} />
          <Route path="/test" element={<Content />} />
          <Route path="/detail/:type/:id" element={<Detail />} />
          <Route
            path="/sub-detail/:type/:id/:name/:idDivisi"
            element={<SubDetail />}
          />
          <Route
            path="/sub-details/:type/:id/:name/:idDivisi/:month"
            element={<SubDetail />}
          />
        </Route>
        <Route path="/home" element={<ParentHome />} />
      </Route>
      <Route path="/" element={<Login />} />
    </Routes>
  );
};

export default AppRouter;
