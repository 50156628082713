import React, { useState, useEffect } from "react";
import {
  getBreakTarget,
  getProductAch,
  getUserAch,
} from "../../service/ratio/RatioService";
import Swal from "sweetalert2";
import Full from "../../load/Loading";
import "../../App.css";
import numeral from "numeral";
import moment from "moment";
import ModalProductMonitor from "./ModalProductMonitor";

function MonitorData({ month, rank_name, team_id, division_id }) {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const [DataBreakTarget, setDataBreakTarget] = useState([]);
  const [subtotalMonitor, setSubtotalMonitor] = useState("");
  const [targetMonth, setTargetMonth] = useState("");
  const [DataProductAch, setDataProductAch] = useState([]);
  const [DataProductSubTarget, setDataProductSubTarget] = useState("");
  const [DataProductSubSalesCur, setDataProductSubSalesCur] = useState("");
  const [DataProductSubSales3m, setDataProductSubSales3m] = useState("");
  const [DataUserAch, setDataUserAch] = useState([]);
  const [DataUserSubTarget, setDataUserSubTarget] = useState("");
  const [DataUserSubSalesCur, setDataUserSubSalesCur] = useState("");
  const [DataUserSubSales3m, setDataUserSubSales3m] = useState("");
  const [modalIsOpenLog, setModalIsOpen] = useState(false);
  const [idProduct, setIdProduct] = useState("");
  const [nameProduct, setName] = useState("");

  useEffect(() => {
    SelectBreak();
  }, [team_id]);

  const SelectBreak = (tabName) => {
    showLoader();
    setActiveTab(tabName);
    const payload = {
      rank: division_id,
      team_id: team_id,
      periode: month,
    };
    getBreakTarget(payload)
      .then((res) => {
        if (res.data.success === true) {
          setDataBreakTarget(res.data.data.data);
          setSubtotalMonitor(res.data.data.subtotal_value);
          setTargetMonth(res.data.data.target_month);
        } else {
          setTargetMonth(null);
        }

        hideLoader();
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
        hideLoader();
      });
  };

  const SelectProductAch = (tabName) => {
    setActiveTab(tabName);
    showLoader();

    const payload = {
      rank: division_id,
      team_id: team_id,
      periode: month,
    };
    getProductAch(payload)
      .then((res) => {
        setDataProductAch(res.data.data.data);
        setDataProductSubTarget(res.data.data.subtotal_target_cur);
        setDataProductSubSalesCur(res.data.data.subtotal_sales_cur);
        setDataProductSubSales3m(res.data.data.subtotal_avg_sales);
        hideLoader();
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
        hideLoader();
      });
  };

  const SelectUserAch = (tabName) => {
    setActiveTab(tabName);
    showLoader();

    const payload = {
      rank: division_id,
      team_id: team_id,
      periode: month,
    };
    getUserAch(payload)
      .then((res) => {
        hideLoader();
        setDataUserAch(res.data.data.data);
        setDataUserSubTarget(res.data.data.subtotal_target_cur);
        setDataUserSubSalesCur(res.data.data.subtotal_sales_cur);
        setDataUserSubSales3m(res.data.data.subtotal_avg_sales);
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
        hideLoader();
      });
  };
  const SelectMonitorR = (tabName) => {
    setActiveTab(tabName);
  };
  const SelectMonitorSaldo = (tabName) => {
    setActiveTab(tabName);
  };
  const detailProduct = (id, name) => {
    setModalIsOpen(true);
    setIdProduct(id);
    setName(name);
  };

  const closeModalLog = () => {
    setModalIsOpen(false);
  };

  const formatToRupiah = (value) => {
    return numeral(value).format("0,0");
  };

  const [activeTab, setActiveTab] = useState("BreakdownTarget");

  useEffect(() => {
    // Set the active tab to 'BreakdownTarget' whenever DataBreakTarget is updated
    setActiveTab("BreakdownTarget");
  }, [DataBreakTarget]);

  useEffect(() => {
    const scripts = [];
    const datatableScript = document.createElement("script");
    datatableScript.async = true;
    datatableScript.textContent = `$(document).ready(function() {
            // Initialize DataTable here
            $('#example290').DataTable({
                // DataTable configuration here
                "retrieve": true,
                "paging": false,
                "lengthChange": false,
                "searching": true,
                "ordering": true,
                "info": false,
                "autoWidth": false,
                "responsive": false,
                // Other adjustments
            });
        });`;

    document.body.appendChild(datatableScript);
    scripts.push(datatableScript);
  }, [DataBreakTarget]);

  useEffect(() => {
    const scripts = [];
    const datatableScript = document.createElement("script");
    datatableScript.async = true;
    datatableScript.textContent = `$(document).ready(function() {
            // Initialize DataTable here
            $('#example291').DataTable({
                // DataTable configuration here
                "retrieve": true,
                "paging": false,
                "lengthChange": false,
                "searching": true,
                "ordering": true,
                "info": false,
                "autoWidth": false,
                "responsive": false,
                // Other adjustments
            });
        });`;

    document.body.appendChild(datatableScript);
    scripts.push(datatableScript);
  }, [DataProductAch]);

  useEffect(() => {
    const scripts = [];
    const datatableScript = document.createElement("script");
    datatableScript.async = true;
    datatableScript.textContent = `$(document).ready(function() {
            // Initialize DataTable here
            $('#example292').DataTable({
                // DataTable configuration here
                "retrieve": true,
                "paging": false,
                "lengthChange": false,
                "searching": true,
                "ordering": true,
                "info": false,
                "autoWidth": false,
                "responsive": false,
                // Other adjustments
            });
        });`;

    document.body.appendChild(datatableScript);
    scripts.push(datatableScript);
  }, [DataUserAch]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-sm-12 mt-6">
          <div className="card card-info card-outline card-tabs card-outline-tabs">
            <div className="card-header p-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-one-tab"
                role="tablist"
              >
                <li className="nav-item col-12 col-lg-4 col-md-12 p-0 pt-0 ">
                  <a
                    className={`nav-link text-center ${
                      activeTab === "BreakdownTarget" ? "active" : ""
                    }`}
                    id="custom-tabs-one-BreakdownTarget-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-BreakdownTarget"
                    role="tab"
                    aria-controls="custom-tabs-one-BreakdownTarget"
                    aria-selected={activeTab === "BreakdownTarget"}
                    onClick={() => SelectBreak("BreakdownTarget")}
                  >
                    Breakdown Target{" "}
                    {/* {targetMonth !== null ? (
                      <p className="text-redBrave">
                        {moment(targetMonth).format("MM-YYYY", {
                          locale: "id",
                        })}
                      </p>
                    ) : null} */}
                  </a>
                </li>
                <li className="nav-item col-12 col-lg-4 col-md-12 p-0 pt-0">
                  <a
                    className={`nav-link text-center ${
                      activeTab === "MonitoringProduct" ? "active" : ""
                    }`}
                    id="custom-tabs-one-MonitoringProduct-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-MonitoringProduct"
                    role="tab"
                    aria-controls="custom-tabs-one-MonitoringProduct"
                    aria-selected={activeTab === "MonitoringProduct"}
                    onClick={() => SelectProductAch("MonitoringProduct")}
                  >
                    Monitoring Product Ach %
                  </a>
                </li>
                <li className="nav-item col-12 col-lg-4 col-md-12 p-0 pt-0">
                  <a
                    className={`nav-link text-center ${
                      activeTab === "MonitoringUser" ? "active" : ""
                    }`}
                    id="custom-tabs-one-MonitoringUser-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-MonitoringUser"
                    role="tab"
                    aria-controls="custom-tabs-one-MonitoringUser"
                    aria-selected={activeTab === "MonitoringUser"}
                    onClick={() => SelectUserAch("MonitoringUser")}
                  >
                    Monitoring User Ach %
                  </a>
                </li>
                {/* <li className="nav-item col-12 col-lg-2 col-md-12 p-0 pt-0">
                  <a
                    className={`nav-link text-center ${
                      activeTab === "MonitoringR" ? "active" : ""
                    }`}
                    id="custom-tabs-one-MonitoringR-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-MonitoringR"
                    role="tab"
                    aria-controls="custom-tabs-one-MonitoringR"
                    aria-selected={activeTab === "MonitoringR"}
                    onClick={() => SelectMonitorR("MonitoringR")}
                  >
                    Monitoring R
                  </a>
                </li>
                <li className="nav-item col-12 col-lg-2 col-md-12 p-0 pt-0">
                  <a
                    className={`nav-link text-center ${
                      activeTab === "MonitoringSaldo" ? "active" : ""
                    }`}
                    id="custom-tabs-one-MonitoringSaldo-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-MonitoringSaldo"
                    role="tab"
                    aria-controls="custom-tabs-one-MonitoringSaldo"
                    aria-selected={activeTab === "MonitoringSaldo"}
                    onClick={() => SelectMonitorSaldo("MonitoringSaldo")}
                  >
                    Monitoring Saldo
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-one-tabContent">
                <div
                  className={`tab-pane fade ${
                    activeTab === "BreakdownTarget" ? "show active" : ""
                  }`}
                  id="custom-tabs-one-BreakdownTarget"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-BreakdownTarget-tab"
                >
                  {DataBreakTarget.length !== 0 ? (
                    <div className="mt-6">
                      <table
                        className="table table-bordered table-hover table-scroll"
                        id="example290"
                        style={{
                          fontSize: "12px",
                          fontWeight: "semiBold",
                          display: "block",
                          overflow: "scroll",
                          height: "480px",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#736098",
                            color: "white",
                            textAlign: "center",
                            top: "0",
                            zIndex: "10",
                            position: "sticky",
                            fontWeight: "reguler",
                          }}
                        >
                          <tr className="text-center">
                            <th
                              style={{
                                left: "0",
                                zIndex: "5",
                                position: "sticky",
                                verticalAlign: "middle",
                                backgroundColor: "#736098",
                              }}
                            >
                              NO
                            </th>
                            <th
                              style={{
                                left: "62px",
                                zIndex: "5",
                                position: "sticky",
                                verticalAlign: "middle",
                                backgroundColor: "#736098",
                              }}
                            >
                              PRODUCT
                            </th>
                            <th
                              style={{
                                left: "165px",
                                zIndex: "5",
                                position: "sticky",
                                verticalAlign: "middle",
                                backgroundColor: "#736098",
                              }}
                            >
                              USER
                            </th>
                            <th
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              KS
                            </th>
                            <th>
                              R <br></br>(Resep per Hari)
                            </th>
                            <th>
                              Rx <br></br>(Jumlah Unit per Resep)
                            </th>
                            <th
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              HK
                            </th>
                            <th
                              style={{
                                textAlign: "right",
                                verticalAlign: "middle",
                              }}
                            >
                              {formatToRupiah(subtotalMonitor)}
                            </th>
                          </tr>
                        </thead>

                        <tbody style={{ textAlign: "right" }}>
                          {DataBreakTarget?.map((list, index) => {
                            return (
                              <tr
                                key={index}
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <td
                                  style={{
                                    left: "0px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                  width={"1%"}
                                >
                                  {index + 1}.
                                </td>
                                <td
                                  style={{
                                    left: "62px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  {list.product_list_name}
                                </td>
                                <td
                                  style={{
                                    left: "165px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  {list.user_name}
                                </td>
                                <td>{list.ks} %</td>
                                <td>{list.R}</td>
                                <td>{list.Rx}</td>
                                <td>{list.HK}</td>
                                <td
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  {formatToRupiah(list.value)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <h6 className="text-center text-bold text-lg">
                      Belum Ada Data
                    </h6>
                  )}
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "MonitoringProduct" ? "show active" : ""
                  }`}
                  id="custom-tabs-one-MonitoringProduct"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-MonitoringProduct-tab"
                >
                  {DataProductAch.length !== 0 ? (
                    <div className="mt-6">
                      <table
                        className="table table-bordered table-hover table-scroll"
                        id="example291"
                        style={{
                          fontSize: "12px",
                          fontWeight: "semiBold",
                          display: "block",
                          overflow: "scroll",
                          height: "480px",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#527C7F",
                            color: "white",
                            textAlign: "center",
                            top: "0",
                            zIndex: "10",
                            position: "sticky",
                            fontWeight: "reguler",
                          }}
                        >
                          <tr className="text-center">
                            <th
                              style={{
                                left: "0",
                                zIndex: "5",
                                position: "sticky",
                                verticalAlign: "middle",
                                backgroundColor: "#527C7F",
                              }}
                            >
                              NO
                            </th>
                            <th
                              style={{
                                left: "60px",
                                zIndex: "5",
                                position: "sticky",
                                verticalAlign: "middle",
                                backgroundColor: "#527C7F",
                              }}
                            >
                              PRODUCT
                            </th>
                            <th
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              SALES (M)
                            </th>
                            <th>AVG SALES (3M)</th>
                            <th
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              TARGET
                            </th>
                            <th
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              ACHIEVEMENT %
                            </th>
                            <th>AVG ACHIEVEMENT % (3M)</th>
                          </tr>
                        </thead>

                        <tbody style={{ textAlign: "right" }}>
                          {DataProductAch?.map((list, index) => {
                            return (
                              <tr
                                key={index}
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "center",
                                    left: "0px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                  width={"1%"}
                                >
                                  {index + 1}.
                                </td>
                                <td
                                  style={{
                                    left: "60px",
                                    textAlign: "center",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  {list.product_list_name}
                                </td>
                                <td>{formatToRupiah(list.sales_cur)}</td>
                                <td>{formatToRupiah(list.avg_sales)}</td>
                                <td>{formatToRupiah(list.target_cur)}</td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {list.ach_cur !== null ? (
                                    <span> {list.ach_cur} %</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {list.avg_ach_3m !== null ? (
                                    <span> {list.avg_ach_3m} %</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot
                          style={{ textAlign: "right" }}
                          className="sticky bottom-0 z-10"
                        >
                          <tr className="bg-white border border-slate-800 font-bold">
                            <td
                              colSpan={"2"}
                              style={{
                                textAlign: "center",
                                left: "0px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              TOTAL
                            </td>

                            <td>{formatToRupiah(DataProductSubSalesCur)}</td>
                            <td>{formatToRupiah(DataProductSubSales3m)}</td>
                            <td>{formatToRupiah(DataProductSubTarget)}</td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  ) : (
                    <h6 className="text-center text-bold text-lg">
                      Belum Ada Data
                    </h6>
                  )}
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "MonitoringUser" ? "show active" : ""
                  }`}
                  id="custom-tabs-one-MonitoringUser"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-MonitoringUser-tab"
                >
                  {DataUserAch.length !== 0 ? (
                    <div className="mt-6">
                      <table
                        className="table table-bordered table-hover table-scroll"
                        id="example292"
                        style={{
                          fontSize: "12px",
                          fontWeight: "semiBold",
                          display: "block",
                          overflow: "scroll",
                          height: "480px",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#735F60",
                            color: "white",
                            textAlign: "center",
                            top: "0",
                            zIndex: "10",
                            position: "sticky",
                            fontWeight: "reguler",
                          }}
                        >
                          <tr className="text-center">
                            <th
                              style={{
                                left: "0",
                                zIndex: "5",
                                position: "sticky",
                                verticalAlign: "middle",
                                backgroundColor: "#735F60",
                              }}
                            >
                              NO
                            </th>
                            <th
                              style={{
                                left: "60px",
                                zIndex: "5",
                                position: "sticky",
                                verticalAlign: "middle",
                                backgroundColor: "#735F60",
                              }}
                            >
                              USER
                            </th>
                            <th
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              SALES (M)
                            </th>
                            <th>AVG SALES (3M)</th>
                            <th
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              TARGET
                            </th>
                            <th
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              ACHIEVEMENT %
                            </th>
                            <th>AVG ACHIEVEMENT % (3M)</th>
                          </tr>
                        </thead>

                        <tbody style={{ textAlign: "right" }}>
                          {DataUserAch?.map((list, index) => {
                            return (
                              <tr
                                key={index}
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "center",
                                    left: "0px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                  width={"1%"}
                                >
                                  {index + 1}.
                                </td>
                                <td
                                  style={{
                                    left: "60px",
                                    textAlign: "center",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    fontStyle: "italic",
                                    color: "#3b82f6",
                                  }}
                                  onClick={() =>
                                    detailProduct(list.user_id, list.user_name)
                                  }
                                >
                                  {list.user_name}
                                </td>
                                <td>{formatToRupiah(list.sales_cur)}</td>
                                <td>{formatToRupiah(list.avg_sales)}</td>
                                <td>{formatToRupiah(list.target_cur)}</td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {list.ach_cur !== null ? (
                                    <span>{list.ach_cur} %</span>
                                  ) : (
                                    <span>-</span>
                                  )}{" "}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {list.avg_ach_3m !== null ? (
                                    <span>{list.avg_ach_3m} %</span>
                                  ) : (
                                    <span>-</span>
                                  )}{" "}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot
                          style={{ textAlign: "right" }}
                          className="sticky bottom-0 z-10"
                        >
                          <tr className="bg-white border border-slate-800 font-bold">
                            <td
                              colSpan={"2"}
                              style={{
                                textAlign: "center",

                                left: "0px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              TOTAL
                            </td>

                            <td> {formatToRupiah(DataUserSubSalesCur)}</td>
                            <td> {formatToRupiah(DataUserSubSales3m)}</td>
                            <td> {formatToRupiah(DataUserSubTarget)}</td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  ) : (
                    <h6 className="text-center text-bold text-lg">
                      Belum Ada Data
                    </h6>
                  )}
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "MonitoringR" ? "show active" : ""
                  }`}
                  id="custom-tabs-one-MonitoringR"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-MonitoringR-tab"
                >
                  <h6 className="text-center text-bold text-lg">
                    Belum Ada Data
                  </h6>
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "MonitoringSaldo" ? "show active" : ""
                  }`}
                  id="custom-tabs-one-MonitoringSaldo"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-MonitoringSaldo-tab"
                >
                  <h6 className="text-center text-bold text-lg">
                    Belum Ada Data
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalIsOpenLog ? (
        <ModalProductMonitor
          onCloseModal={closeModalLog}
          nameProduct={nameProduct}
          idProduct={idProduct}
          team_id={team_id}
          month={month}
          division_id={division_id}
        />
      ) : null}
      {loader}
    </div>
  );
}

export default MonitorData;
