import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import back from "../../img/left.png";
import "../../../src/App.css";
import moment from "moment";
import {
  getDataRatioSubDetail,
  getDataRatioArea,
  getDataRatioChanel,
} from "../../service/ratio/RatioService";
import Full from "../../load/Loading";
import Swal from "sweetalert2";
import Highcharts from "highcharts";
import numeral from "numeral";

function SubDetailData() {
  //naivigasi
  const navigate = useNavigate();
  const targetRefArea = useRef(null);
  const targetRefChanel = useRef(null);
  const targetRefOutlet = useRef(null);
  //Loading
  const [loader, showLoader, hideLoader] = Full();
  //start untuk priode
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const lastMonth = currentDate.getMonth();
  const currentMonthString = String(currentMonth).padStart(2, "0");
  const lastMonthString = String(lastMonth).padStart(2, "0");
  const nowDate = currentYear + "-" + currentMonthString;
  //get localstrorage
  const rank_name = localStorage.getItem("rank_name");
  const division_id = localStorage.getItem("division_id");
  const rank = localStorage.getItem("rank");
  const params = useParams();
  const type = params.type;
  const idArea = params.id;
  const name = params.name;
  const months = params.month;
  const idDivisi = params.idDivisi;

  const monthData = months ? months : nowDate;

  const convertMonthFormat = (monthData) => {
    const [year, month] = monthData.split("-");
    const monthNames = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const monthName = monthNames[parseInt(month) - 1];
    return `${monthName} ${year}`;
  };
  const formattedMonth = convertMonthFormat(monthData);

  //priode
  const [periode, setPriode] = useState("");

  const convertMonthFormat1 = (periode) => {
    const [year, month] = periode.split("-");
    const monthNames = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const monthName = monthNames[parseInt(month) - 1];
    return `${monthName} ${year}`;
  };

  const formattedMonth1 = convertMonthFormat1(periode);

  //id
  const [idUser, setIdUser] = useState("");
  const [idChanel, setIdChanel] = useState("");
  //update waktu
  const [updateTime, setUpdate] = useState("");
  //name tabel
  const [nameProduk, setNameProduct] = useState("");
  const [nameChanel, setNameChanel] = useState("");
  const [nameOutlet, setNameOutlet] = useState("");
  //data pie
  const [ratioDetail, setDataRatioSubDetail] = useState([]);
  //data tabel
  const [dataRatioArea, setDataRatioArea] = useState({});
  const [dataRatioChanel, setDataRatioChanel] = useState({});
  const [dataRatioOtlet, setDataRatioOutlet] = useState({});
  //show tabel
  const [showDetailArea, setShowDetailArea] = useState(false);
  const [showDetailChanel, setShowDetailChanel] = useState(false);
  const [showDetailOutlet, setShowDetailOutlet] = useState(false);
  const [showMonth, setShowMonth] = useState(false);

  //tabel 1
  const data = [];
  for (const key in ratioDetail) {
    if (Object.hasOwnProperty.call(ratioDetail, key)) {
      const element = ratioDetail[key];
      data.push(element);
    }
  }
  //table 2
  const dataArea = [];
  for (const key in dataRatioArea) {
    if (Object.hasOwnProperty.call(dataRatioArea, key)) {
      const element = dataRatioArea[key];
      dataArea.push(element);
    }
  }
  //tabel 3
  const dataChanel = [];
  for (const key in dataRatioChanel) {
    if (Object.hasOwnProperty.call(dataRatioChanel, key)) {
      const element = dataRatioChanel[key];
      dataChanel.push(element);
    }
  }
  //tabel 4
  const dataOutlet = [];
  for (const key in dataRatioOtlet) {
    if (Object.hasOwnProperty.call(dataRatioOtlet, key)) {
      const element = dataRatioOtlet[key];
      dataOutlet.push(element);
    }
  }
  //data pie chart
  const dataArray = Object.values(ratioDetail);

  const pctArray = dataArray.map((item) => ({
    name: item.name || item.id,
    y: item.pct !== 0 ? item.pct : null,
    user_transaksi: item.user_transaksi,
  }));

  //pertama load
  useEffect(() => {
    dataPieChart();
  }, []);

  //pilih periode
  const handleMonth = () => {
    setShowMonth(true);
    dataPieChart();
    setShowDetailArea();
    setShowDetailChanel();
    setShowDetailOutlet();
  };

  //fungsi kembali
  const handleClick = () => {
    navigate(`/dashboard/${idDivisi}`);
  };
  //show chart
  const dataPieChart = () => {
    let payload;
    if (rank_name == "PD" || rank_name == "OD") {
      if (type == "BDU") {
        payload = {
          rank: rank,
          type: type,
          search_name: "detail_user",
          search_id: idArea,
          user_id: idArea,
          team_id: idArea,
          periode: periode ? periode : months ? months : "",
        };
      } else if (type == "STO") {
        payload = {
          rank: rank,
          type: type,
          search_name: "detail_product",
          search_id: idArea,
          user_id: idArea,
          team_id: idArea,
          periode: periode ? periode : months ? months : "",
        };
      }
    } else {
      if (type == "BDU") {
        payload = {
          rank: idDivisi,
          type: type,
          search_name: "detail_user",
          search_id: idArea,
          user_id: idArea,
          team_id: idArea,
          periode: periode ? periode : months ? months : "",
        };
      } else if (type == "STO") {
        payload = {
          rank: idDivisi,
          type: type,
          search_name: "detail_product",
          search_id: idArea,
          user_id: idArea,
          team_id: idArea,
          periode: periode ? periode : months ? months : "",
        };
      }
    }
    showLoader();
    getDataRatioSubDetail(payload)
      .then((res) => {
        if (res.data.success == true) {
          setUpdate(res.data.data.update_time);
          initializeDataTables();
          if (type == "BDU") {
            setDataRatioSubDetail(res.data.data["BDU"]);
            initializeDataTables();
          } else if (type == "STO") {
            setDataRatioSubDetail(res.data.data["STO"]);
            setDataRatioArea(res.data.data["STO"]);
            initializeDataTables();
          }
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  };

  //fungsi tabel 2
  const detailProduct = (id_user, next_data) => {
    setIdUser(id_user);
    if (targetRefArea.current) {
      targetRefArea.current.scrollIntoView({ behavior: "smooth" });
    }
    setShowDetailChanel(false);
    setShowDetailOutlet(false);
    setNameProduct(id_user);
    let payload;
    if (rank_name == "PD" || rank_name == "OD") {
      payload = {
        rank: rank,
        type: type,
        search_name: next_data,
        search_id: id_user,
        user_id: idArea,
        team_id: idArea,
        periode: periode ? periode : months ? months : "",
      };
    } else {
      payload = {
        rank: idDivisi,
        type: type,
        search_name: next_data,
        search_id: id_user,
        user_id: idArea,
        team_id: idArea,
        periode: periode ? periode : months ? months : "",
      };
    }
    showLoader();
    getDataRatioArea(payload)
      .then((res) => {
        if (res.data.success == true) {
          initializeDataTables();
          if (type == "BDU") {
            setDataRatioArea(res.data.data["BDU"]);
            initializeDataTables();
          } else if (type == "STO") {
            setDataRatioArea(res.data.data["STO"]);
            initializeDataTables();
          }
          hideLoader();
          setShowDetailArea(true);
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  };
  //fungsi tabel 3
  const detailChannel = (id_user, next_data, name) => {
    setIdChanel(id_user);
    if (targetRefChanel.current) {
      targetRefChanel.current.scrollIntoView({ behavior: "smooth" });
    }

    setShowDetailOutlet(false);
    setNameChanel(name);
    let payload;
    if (rank_name == "PD" || rank_name == "OD") {
      payload = {
        rank: rank,
        type: type,
        search_name: next_data,
        search_id: id_user,
        user_id: idUser ? idUser : idArea,
        team_id: idArea,
        periode: periode ? periode : months ? months : "",
      };
    } else {
      payload = {
        rank: idDivisi,
        type: type,
        search_name: next_data,
        search_id: id_user,
        user_id: idUser ? idUser : idArea,
        team_id: idArea,
        periode: periode ? periode : months ? months : "",
      };
    }
    showLoader();
    getDataRatioChanel(payload)
      .then((res) => {
        if (res.data.success == true) {
          if (type == "BDU") {
            setDataRatioChanel(res.data.data["BDU"]);
            initializeDataTables();
          } else if (type == "STO") {
            setDataRatioChanel(res.data.data["STO"]);
            initializeDataTables();
          }
          hideLoader();
          setShowDetailChanel(true);
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  };
  //fungsi tabel 4
  const detailOutlet = (id_user, next_data, name) => {
    if (targetRefOutlet.current) {
      targetRefOutlet.current.scrollIntoView({ behavior: "smooth" });
    }
    setNameOutlet(name);
    let payload;
    if (rank_name == "PD" || rank_name == "OD") {
      payload = {
        rank: rank,
        type: type,
        search_name: next_data,
        search_id: id_user,
        user_id: idUser ? idUser : idArea,
        team_id: idArea,
        product_id: idChanel ? idChanel : "",
        periode: periode ? periode : months ? months : "",
      };
    } else {
      payload = {
        rank: idDivisi,
        type: type,
        search_name: next_data,
        search_id: id_user,
        user_id: idUser ? idUser : idArea,
        team_id: idArea,
        product_id: idChanel ? idChanel : "",
        periode: periode ? periode : months ? months : "",
      };
    }
    showLoader();
    getDataRatioChanel(payload)
      .then((res) => {
        if (res.data.success == true) {
          if (type == "BDU") {
            setDataRatioOutlet(res.data.data["BDU"]);
            initializeDataTables();
          } else if (type == "STO") {
            setDataRatioOutlet(res.data.data["STO"]);
            initializeDataTables();
          }
          hideLoader();
          setShowDetailOutlet(true);
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  };
  //konversi nilai
  const formatToRupiah = (value) => {
    return numeral(value).format("0,0"); // Format angka dengan koma sebagai pemisah ribuan
  };

  // Inisialisasi variabel total
  let totalCurrentCt = 0;
  let totalCurrentSaldo = 0;
  let totalLastKU = 0;
  let totalM3 = 0;
  let totalM2 = 0;
  let totalM1 = 0;
  let totalM = 0;
  let countNonZeroM3 = 0;
  let countNonZeroM2 = 0;
  let countNonZeroM1 = 0;
  let countNonZeroM = 0;

  // Map data dan tambahkan nilai ke total
  data.forEach((list) => {
    totalCurrentCt += parseInt(list.current_ct);
    totalCurrentSaldo += parseInt(list.current_saldo);
    totalLastKU += parseInt(list.last_ku);
    totalM3 += parseInt(list.m_3);
    totalM2 += parseInt(list.m_2);
    totalM1 += parseInt(list.m_1);
    totalM += parseInt(list.m);
    if (parseInt(list.m_3) !== 0) {
      totalM3 += parseInt(list.m_3);
      countNonZeroM3++;
    }
    if (parseInt(list.m_2) !== 0) {
      totalM3 += parseInt(list.m_2);
      countNonZeroM2++;
    }
    if (parseInt(list.m_1) !== 0) {
      totalM3 += parseInt(list.m_1);
      countNonZeroM1++;
    }
    if (parseInt(list.m) !== 0) {
      totalM3 += parseInt(list.m);
      countNonZeroM++;
    }
  });

  // Inisialisasi variabel total
  let totalM3UnitAreaGrand = 0;
  let totalM2UnitAreaGrand = 0;
  let totalM1UnitAreaGrand = 0;
  let totalMUnitAreaGrand = 0;
  let totalM3AreaGrand = 0;
  let totalM2AreaGrand = 0;
  let totalM1AreaGrand = 0;
  let totalMAreaGrand = 0;

  // Map dataArea dan tambahkan nilai ke total
  dataArea.forEach((list) => {
    totalM3UnitAreaGrand += parseInt(list.m_3_unit);
    totalM2UnitAreaGrand += parseInt(list.m_2_unit);
    totalM1UnitAreaGrand += parseInt(list.m_1_unit);
    totalMUnitAreaGrand += parseInt(list.m_unit);
    totalM3AreaGrand += parseInt(list.m_3);
    totalM2AreaGrand += parseInt(list.m_2);
    totalM1AreaGrand += parseInt(list.m_1);
    totalMAreaGrand += parseInt(list.m);
  });

  // Inisialisasi variabel total
  let totalM3UnitChanel = 0;
  let totalM2UnitChanel = 0;
  let totalM1UnitChanel = 0;
  let totalMUnitChanel = 0;
  let totalM3Chanel = 0;
  let totalM2Chanel = 0;
  let totalM1Chanel = 0;
  let totalMChanel = 0;

  // Map dataChanel dan tambahkan nilai ke total
  dataChanel.forEach((list) => {
    totalM3UnitChanel += parseInt(list.m_3_unit);
    totalM2UnitChanel += parseInt(list.m_2_unit);
    totalM1UnitChanel += parseInt(list.m_1_unit);
    totalMUnitChanel += parseInt(list.m_unit);
    totalM3Chanel += parseInt(list.m_3);
    totalM2Chanel += parseInt(list.m_2);
    totalM1Chanel += parseInt(list.m_1);
    totalMChanel += parseInt(list.m);
  });
  // Inisialisasi variabel total
  let totalM3UnitOutlet = 0;
  let totalM2UnitOutlet = 0;
  let totalM1UnitOutlet = 0;
  let totalMUnitOutlet = 0;
  let totalM3Outlet = 0;
  let totalM2Outlet = 0;
  let totalM1Outlet = 0;
  let totalMOutlet = 0;

  // Map dataOutlet dan tambahkan nilai ke total
  dataOutlet.forEach((list) => {
    totalM3UnitOutlet += parseInt(list.m_3_unit);
    totalM2UnitOutlet += parseInt(list.m_2_unit);
    totalM1UnitOutlet += parseInt(list.m_1_unit);
    totalMUnitOutlet += parseInt(list.m_unit);
    totalM3Outlet += parseInt(list.m_3);
    totalM2Outlet += parseInt(list.m_2);
    totalM1Outlet += parseInt(list.m_1);
    totalMOutlet += parseInt(list.m);
  });

  // Group the data by product_list_group_id
  const groupedData = {};
  dataArea.forEach((item) => {
    const groupId = item.product_list_group_id;
    if (!groupedData[groupId]) {
      groupedData[groupId] = [];
    }
    groupedData[groupId].push(item);
  });
  //showing pie chart
  useEffect(() => {
    let coloring;

    if (idDivisi == "27" || rank == "27") {
      coloring = ["red"];
    } else if (idDivisi == "14" || rank == "14") {
      coloring = ["blue"];
    } else if (idDivisi == "20" || rank == "20") {
      coloring = ["green"];
    }
    Highcharts.chart("containerPie", {
      chart: {
        type: "pie",
      },
      title: {
        text: `${name} (${type})`,
        align: "center",
      },

      accessibility: {
        announceNewData: {
          enabled: false,
        },
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: [
            {
              enabled: true,
              distance: 30,
            },
            {
              enabled: true,
              distance: "-30%",
              format: "{point.name}",
              style: {
                fontSize: "1.2em",
                textOutline: "none",
                opacity: 0.7,
              },
              filter: {
                operator: ">",
                property: "percentage",
                value: 5,
              },
              format: "{point.y:.1f}%",
              style: {
                fontSize: "12px",
                textOutline: "none",
                color: "#ffffff",
              },
            },
          ],
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.1f}%</b>  </b><br/>',
      },
      colors: coloring,
      series: [
        {
          name: type,
          data: pctArray,
        },
      ],
    });
  }, [pctArray]);

  // datatable order by
  useEffect(() => {
    Object.keys(groupedData).forEach((groupId) => {
      const script = document.createElement("script");
      script.src = `${process.env.PUBLIC_URL}/js/content.js`;
      script.async = true;
      document.body.appendChild(script);
      const datatableScript = document.createElement("script");
      datatableScript.textContent = `$(document).ready(function() {
      // Inisialisasi Datable di sini
      $('#example2_${groupId}').DataTable({
        // Konfigurasi Datable di sini
        "retrieve": true,
        "paging": false,
        "lengthChange": false,
        "searching": false,
        "ordering": true,
        "info": false,
        "autoWidth": false,
        "responsive": false,
        // Penyesuaian lainnya
      });
    });`;
      document.body.appendChild(datatableScript);
    });
  }, [groupedData]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     const script = document.createElement("script");
  //     script.src = `${process.env.PUBLIC_URL}/js/content.js`;
  //     script.async = true;
  //     document.body.appendChild(script);
  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }, 2000);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [groupedData, showDetailArea, showDetailChanel, showDetailOutlet]);

  const initializeDataTables = () => {
    // Initialize DataTables library here after data is fetched and rendered
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/content.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  return (
    <>
      <section className="px-6 mx-auto pb-60 pt-14">
        <h3 className="text-reguler text-[14px] lg:text-[20px] md:text-[18px] mb-2">
          Team : <span className="text-bold">{rank_name}</span>
        </h3>
        <div className="border-[1px] border-[#437998] rounded-xl p-1 mb-4 ">
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="w-full lg:w-[500px] lg:my-6 lg:mx-2 my-2">
              <div className="grid grid-cols-2 gap-2 xl:grid-cols-2 xl:gap-1">
                <select
                  onChange={(e) => setPriode(e.target.value)}
                  className="block max-w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                >
                  <option value="" disabled selected>
                    Pilih Salah Satu
                  </option>
                  <option value={currentYear + "-" + currentMonthString}>
                    {currentMonthString + "-" + currentYear}
                  </option>
                  <option value={currentYear + "-" + lastMonthString}>
                    {lastMonthString + "-" + currentYear}
                  </option>
                </select>
                <button
                  type="button"
                  onClick={handleMonth}
                  className="bg-redBrave rounded-lg px-4 py-2 font-bold text-white xl:mr-36"
                >
                  Proses
                </button>
              </div>
            </div>

            <div className="w-full lg:w-1/3 lg:my-6 lg:mx-2 my-2 border-none border-[#FFE4E4] rounded-xl bg-[#FFE4E4]">
              <h3 className="text-medium text-center text-[12px] lg:text-[16px] px-2 py-2">
                Update terakhir :{" "}
                {moment(updateTime).format("DD MMMM YYYY HH:mm:ss", {
                  locale: "id",
                })}
              </h3>
            </div>
          </div>
        </div>
        <div className="flex flex-row lg:flex-row justify-between">
          <div className="w-full lg:w-[500px] lg:my-6 lg:mx-2 my-2">
            <div
              onClick={handleClick}
              className="flex items-center mb-4 text-redBrave"
            >
              <div className="mr-2">
                <img src={back} className="w-5 h-5" alt="Back" />
              </div>
              <h3 className="text-bold text-[16px] lg:text-[20px] cursor-pointer">
                Beranda
              </h3>
            </div>
          </div>
          <div className="w-full lg:w-[500px] lg:my-6 lg:mx-2 my-2">
            {showMonth ? (
              <>
                <h3 className="text-bold text-[16px] lg:text-[20px]  text-end decoration-solid">
                  Bulan : {formattedMonth1}
                </h3>
              </>
            ) : (
              <>
                <h3 className="text-bold text-[16px] lg:text-[20px]  text-end decoration-solid">
                  Bulan : {formattedMonth}
                </h3>
              </>
            )}
          </div>
        </div>

        <div>
          <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 mb-3">
            <div className="w-full">
              <div className="border-[1px] border-[#437998] rounded-xl p-2">
                <figure className="highcharts-figure">
                  <div id="containerPie" className="lg:py-12"></div>
                </figure>
              </div>
            </div>
          </div>
        </div>

        {type == "BDU" ? (
          <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
            <h3 className="font-semibold text-lg mx-2 mb-3">
              Detail Data dari <span className="text-redBrave">{name}</span>
            </h3>
            <div className="relative overflow-x-auto max-h-[460px]">
              <div className="card-body" style={{ marginTop: "-30px" }}>
                <table
                  className="table table-bordered table-hover"
                  id="example1"
                  style={{ fontSize: "12px", fontWeight: "semiBold" }}
                >
                  <thead
                    style={{
                      backgroundColor: "#437998",
                      color: "white",
                      textAlign: "center",
                      top: "0",
                      zIndex: "10",
                      position: "sticky",
                      fontWeight: "reguler",
                    }}
                  >
                    <tr>
                      <th>No</th>
                      <th>UID</th>
                      <th>CT</th>
                      <th>RB</th>
                      <th>SLD</th>
                      <th>KU</th>
                      <th>TNR</th>
                      <th>M-3</th>
                      <th>M-2</th>
                      <th>M-1</th>
                      <th>M</th>
                    </tr>
                  </thead>

                  <tbody style={{ textAlign: "right" }}>
                    {data.map((list, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                            width={"1%"}
                          >
                            {index + 1}.
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={() => detailProduct(list.id, list.next)}
                            >
                              {list.id}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.current_ct) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {formatToRupiah(list.current_ct)}
                            </span>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {list.current_rb === "100.00"
                              ? "100 %"
                              : `${list.current_rb} %`}
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.current_saldo) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {formatToRupiah(list.current_saldo)}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.last_ku) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {formatToRupiah(list.last_ku)}
                            </span>
                          </td>
                          <td className="text-center">
                            <span
                              className={`${
                                parseFloat(list.lifetime) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {list.lifetime}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.m_3) < 0 ? "text-red-500" : ""
                              }`}
                            >
                              {formatToRupiah(list.m_3)}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.m_2) < 0 ? "text-red-500" : ""
                              }`}
                            >
                              {formatToRupiah(list.m_2)}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.m_1) < 0 ? "text-red-500" : ""
                              }`}
                            >
                              {formatToRupiah(list.m_1)}
                            </span>
                          </td>
                          <td>
                            {" "}
                            <span
                              className={`${
                                parseFloat(list.m) < 0 ? "text-red-500" : ""
                              }`}
                            >
                              {formatToRupiah(list.m)}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot
                    style={{ textAlign: "right" }}
                    className="sticky bottom-0 z-10"
                  >
                    <tr className="bg-white border border-slate-800 font-bold">
                      <td
                        colSpan={"2"}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        USER TRANSAKSI
                      </td>
                      <td colSpan={"5"}></td>
                      <td>
                        {countNonZeroM3} / {data.length}
                      </td>
                      <td>
                        {countNonZeroM2} / {data.length}
                      </td>
                      <td>
                        {countNonZeroM1} / {data.length}
                      </td>
                      <td>
                        {countNonZeroM} / {data.length}
                      </td>
                    </tr>
                    <tr className="bg-white border border-slate-800 font-bold">
                      <td
                        colSpan={"2"}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        TOTAL
                      </td>
                      <td>
                        {" "}
                        <span
                          className={`${
                            parseFloat(totalCurrentCt) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {formatToRupiah(totalCurrentCt)}
                        </span>
                      </td>
                      <td style={{ backgroundColor: "white" }}></td>
                      <td>
                        {" "}
                        <span
                          className={`${
                            parseFloat(totalCurrentSaldo) < 0
                              ? "text-red-500"
                              : ""
                          }`}
                        >
                          {formatToRupiah(totalCurrentSaldo)}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`${
                            parseFloat(totalLastKU) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {formatToRupiah(totalLastKU)}
                        </span>
                      </td>
                      <td style={{ backgroundColor: "white" }}></td>
                      <td>
                        <span
                          className={`${
                            parseFloat(totalM3) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {formatToRupiah(totalM3)}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`${
                            parseFloat(totalM2) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {formatToRupiah(totalM2)}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`${
                            parseFloat(totalM1) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {formatToRupiah(totalM1)}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`${
                            parseFloat(totalM) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {formatToRupiah(totalM)}
                        </span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        ) : null}

        <div style={{ marginTop: "30px" }} ref={targetRefArea}>
          {showDetailArea || type == "STO" ? (
            <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
              <h3 className="font-semibold text-lg mx-2 mb-3">
                {type == "STO" ? (
                  <>
                    Detail Data dari{" "}
                    <span className="text-redBrave">{name}</span>
                  </>
                ) : (
                  <>
                    Detail Data dari{" "}
                    <span className="text-redBrave">{nameProduk}</span>
                  </>
                )}
              </h3>

              <div className="relative overflow-x-auto max-h-[420px]">
                <div className="card-body" style={{ marginTop: "-30px" }}>
                  {Object.keys(groupedData).map((groupId, index) => {
                    const group = groupedData[groupId];
                    const isLastGroup =
                      index === Object.keys(groupedData).length - 1;
                    let totalM3UnitArea = 0;
                    let totalM2UnitArea = 0;
                    let totalM1UnitArea = 0;
                    let totalMUnitArea = 0;
                    let totalM3Area = 0;
                    let totalM2Area = 0;
                    let totalM1Area = 0;
                    let totalMArea = 0;

                    return (
                      <React.Fragment key={groupId}>
                        <table
                          style={{
                            fontSize: "12px",
                            fontWeight: "semiBold",
                          }}
                          className="table table-bordered table-hover"
                          id={`example2_${groupId}`}
                        >
                          <thead
                            style={{
                              backgroundColor: "#6BB7B7",
                              color: "white",
                              textAlign: "center",
                              top: "0",
                              zIndex: "10",
                              position: "sticky",
                              verticalAlignAlign: "middle",
                              fontWeight: "reguler",
                            }}
                          >
                            <tr>
                              <th colSpan={"11"}>
                                {groupedData[groupId][0].product_list_group} (
                                {groupedData[groupId][0].product_list_group_id})
                              </th>
                            </tr>
                            <tr>
                              <th rowSpan={"2"} width="1%">
                                NO
                              </th>
                              <th rowSpan={"2"} width="9.6%">
                                PRD
                              </th>
                              <th colSpan={"4"} width="25%">
                                UNIT
                              </th>
                              <th colSpan={"4"} width="25%">
                                VALUE
                              </th>
                              <th rowSpan={"2"} width="5%">
                                KONTRIBUSI
                              </th>
                            </tr>
                            <tr>
                              <th width="6.3%">M-3</th>
                              <th width="6.3%">M-2</th>
                              <th width="6.3%">M-1</th>
                              <th width="6.3%">M</th>
                              <th width="6.3%">M-3</th>
                              <th width="6.3%">M-2</th>
                              <th width="6.3%">M-1</th>
                              <th width="6.3%">M</th>
                            </tr>
                          </thead>
                          <tbody style={{ textAlign: "right" }}>
                            {group.map((list, index) => {
                              totalM3UnitArea += parseInt(list.m_3_unit);
                              totalM2UnitArea += parseInt(list.m_2_unit);
                              totalM1UnitArea += parseInt(list.m_1_unit);
                              totalMUnitArea += parseInt(list.m_unit);
                              totalM3Area += parseInt(list.m_3);
                              totalM2Area += parseInt(list.m_2);
                              totalM1Area += parseInt(list.m_1);
                              totalMArea += parseInt(list.m);

                              return (
                                <tr key={index}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {index + 1}.
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "left",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span
                                      onClick={() =>
                                        detailChannel(
                                          list.id,
                                          list.next,
                                          list.name
                                        )
                                      }
                                    >
                                      {list.name}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_3_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_3_unit)}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_2_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_2_unit)}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_1_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_1_unit)}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span
                                      className={`${
                                        parseFloat(list.m_unit) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_unit)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`${
                                        parseFloat(list.m_3) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_3)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`${
                                        parseFloat(list.m_2) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_2)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`${
                                        parseFloat(list.m_1) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m_1)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={`${
                                        parseFloat(list.m) < 0
                                          ? "text-red-500"
                                          : ""
                                      }`}
                                    >
                                      {formatToRupiah(list.m)}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {list.kontribusi.toFixed(2)}%
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot className="bg-white border border-slate-800 font-bold sticky bottom-0 z-10">
                            <tr
                              style={{
                                fontWeight: "bold",
                                textAlign: "right",
                              }}
                            >
                              <td
                                colSpan={"2"}
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                SUB TOTAL
                              </td>

                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(
                                      totalM3UnitArea.toLocaleString("id-ID")
                                    ) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {totalM3UnitArea.toLocaleString("id-ID")}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(
                                      totalM2UnitArea.toLocaleString("id-ID")
                                    ) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {totalM2UnitArea.toLocaleString("id-ID")}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(
                                      totalM1UnitArea.toLocaleString("id-ID")
                                    ) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {totalM1UnitArea.toLocaleString("id-ID")}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  className={`${
                                    parseFloat(
                                      totalMUnitArea.toLocaleString("id-ID")
                                    ) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {totalMUnitArea.toLocaleString("id-ID")}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalM3Area) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM3Area)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalM2Area) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM2Area)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalM1Area) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalM1Area)}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`${
                                    parseFloat(totalMArea) < 0
                                      ? "text-red-500"
                                      : ""
                                  }`}
                                >
                                  {formatToRupiah(totalMArea)}
                                </span>
                              </td>
                              <td style={{ backgroundColor: "white" }}></td>
                            </tr>
                            {isLastGroup && (
                              <tr
                                style={{ textAlign: "right" }}
                                key={`total_${groupId}`}
                                className="bg-white border border-slate-800 font-bold sticky bottom-0 z-10"
                              >
                                <td colSpan={2} className="text-center">
                                  GRAND TOTAL
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span
                                    className={`${
                                      parseFloat(
                                        totalM3UnitAreaGrand.toLocaleString(
                                          "id-ID"
                                        )
                                      ) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {totalM3UnitAreaGrand.toLocaleString(
                                      "id-ID"
                                    )}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span
                                    className={`${
                                      parseFloat(
                                        totalM2UnitAreaGrand.toLocaleString(
                                          "id-ID"
                                        )
                                      ) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {totalM2UnitAreaGrand.toLocaleString(
                                      "id-ID"
                                    )}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span
                                    className={`${
                                      parseFloat(
                                        totalM1UnitAreaGrand.toLocaleString(
                                          "id-ID"
                                        )
                                      ) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {totalM1UnitAreaGrand.toLocaleString(
                                      "id-ID"
                                    )}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span
                                    className={`${
                                      parseFloat(
                                        totalMUnitAreaGrand.toLocaleString(
                                          "id-ID"
                                        )
                                      ) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {totalMUnitAreaGrand.toLocaleString(
                                      "id-ID"
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(totalM3AreaGrand) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(totalM3AreaGrand)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(totalM2AreaGrand) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(totalM2AreaGrand)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(totalM1AreaGrand) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(totalM1AreaGrand)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(totalMAreaGrand) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(totalMAreaGrand)}
                                  </span>
                                </td>
                                <td style={{ backgroundColor: "white" }}></td>
                              </tr>
                            )}
                          </tfoot>
                        </table>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div style={{ marginTop: "30px" }} ref={targetRefChanel}>
          {showDetailChanel ? (
            <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
              <h3 className="font-semibold text-lg mx-2 ">
                Detail Data dari{" "}
                <span className="text-redBrave">{nameChanel}</span>
              </h3>
              <div className="relative overflow-x-auto max-h-[460px]">
                <div className="card-body">
                  <table
                    className="table table-bordered table-hover"
                    id="example3"
                    style={{
                      fontSize: "12px",
                      fontWeight: "semiBold",
                    }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#71AF7B",
                        color: "white",
                        textAlign: "center",
                        top: "0",
                        zIndex: "10",
                        position: "sticky",
                        fontWeight: "reguler",
                      }}
                    >
                      <tr className="text-center">
                        <th rowSpan={"2"}>NO</th>
                        <th rowSpan={"2"}>TIPE</th>
                        <th colSpan={"4"}>UNIT</th>
                        <th colSpan={"4"}>VALUE</th>
                      </tr>
                      <tr className="text-center">
                        <th>M-3</th>
                        <th>M-2</th>
                        <th>M-1</th>
                        <th>M</th>
                        <th>M-3</th>
                        <th>M-2</th>
                        <th>M-1</th>
                        <th>M</th>
                      </tr>
                    </thead>

                    <tbody style={{ textAlign: "right" }}>
                      {dataChanel.map((list, index) => {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                              width={"1%"}
                            >
                              {index + 1}.
                            </td>
                            <td
                              style={{
                                textAlign: "left",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                onClick={() =>
                                  detailOutlet(list.id, list.next, list.name)
                                }
                              >
                                {list.name}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_3_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_3_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_2_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_2_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_1_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_1_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_unit)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_3) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_3)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_2) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_2)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_1) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_1)}
                              </span>
                            </td>
                            <td>
                              {" "}
                              <span
                                className={`${
                                  parseFloat(list.m) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m)}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot
                      style={{ textAlign: "right" }}
                      className="sticky bottom-0 z-10"
                    >
                      <tr className="bg-white border border-slate-800 font-bold">
                        <td
                          colSpan={"2"}
                          style={{
                            textAlign: "center",
                          }}
                        >
                          TOTAL
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {" "}
                          <span
                            className={`${
                              parseFloat(totalM3UnitChanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM3UnitChanel)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {" "}
                          <span
                            className={`${
                              parseFloat(totalM2UnitChanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM2UnitChanel)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`${
                              parseFloat(totalM1UnitChanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM1UnitChanel)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`${
                              parseFloat(totalMUnitChanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalMUnitChanel)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM3Chanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM3Chanel)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM2Chanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM2Chanel)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM1Chanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM1Chanel)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalMChanel) < 0 ? "text-red-500" : ""
                            }`}
                          >
                            {formatToRupiah(totalMChanel)}
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div style={{ marginTop: "30px" }} ref={targetRefOutlet}>
          {showDetailOutlet ? (
            <div className="border-[1px] border-[#437998] rounded-xl p-2">
              <h3 className="font-semibold text-lg mx-2">
                Detail Data dari{" "}
                <span className="text-redBrave">{nameOutlet}</span>
              </h3>
              <div className="relative overflow-x-auto max-h-[460px]">
                <div className="card-body">
                  <table
                    className="table table-bordered table-hover"
                    id="example4"
                    style={{
                      fontSize: "12px",
                      fontWeight: "semiBold",
                    }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#AB6969",
                        color: "white",
                        textAlign: "center",
                        top: "0",
                        zIndex: "10",
                        position: "sticky",
                        fontWeight: "reguler",
                      }}
                    >
                      <tr className="text-center">
                        <th rowSpan={"2"}>NO</th>
                        <th rowSpan={"2"}>NAMA</th>
                        <th colSpan={"4"}>UNIT</th>
                        <th colSpan={"4"}>VALUE</th>
                      </tr>
                      <tr className="text-center">
                        <th>M-3</th>
                        <th>M-2</th>
                        <th>M-1</th>
                        <th>M</th>
                        <th>M-3</th>
                        <th>M-2</th>
                        <th>M-1</th>
                        <th>M</th>
                      </tr>
                    </thead>

                    <tbody style={{ textAlign: "right" }}>
                      {dataOutlet.map((list, index) => {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                              width={"1%"}
                            >
                              {index + 1}.
                            </td>
                            <td
                              style={{
                                textAlign: "left",
                              }}
                            >
                              {list.name}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_3_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_3_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_2_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_2_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_1_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_1_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_unit)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_3) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_3)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_2) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_2)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_1) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_1)}
                              </span>
                            </td>
                            <td>
                              {" "}
                              <span
                                className={`${
                                  parseFloat(list.m) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m)}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot
                      style={{ textAlign: "right" }}
                      className="sticky bottom-0 z-10"
                    >
                      <tr className="bg-white border border-slate-800 font-bold">
                        <td
                          colSpan={"2"}
                          style={{
                            textAlign: "center",
                          }}
                        >
                          TOTAL
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {" "}
                          <span
                            className={`${
                              parseFloat(totalM3UnitOutlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM3UnitOutlet)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`${
                              parseFloat(totalM2UnitOutlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM2UnitOutlet)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`${
                              parseFloat(totalM1UnitOutlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM1UnitOutlet)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`${
                              parseFloat(totalMUnitOutlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalMUnitOutlet)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM3Outlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM3Outlet)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM2Outlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM2Outlet)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM1Outlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM1Outlet)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalMOutlet) < 0 ? "text-red-500" : ""
                            }`}
                          >
                            {formatToRupiah(totalMOutlet)}
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </section>
      {loader}
    </>
  );
}

export default SubDetailData;
