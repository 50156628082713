import React, { useState, useEffect, useRef } from "react";
import numeral from "numeral";
import { getDataProduk } from "../../service/ratio/RatioService";
import Full from "../../load/Loading";
import Swal from "sweetalert2";
import moment from "moment";
import StockSubdistProduk from "./StokSubdistProduk";

function StockSubdist({
  dataCabang,
  subdistId,
  rank,
  namaCabangs,
  NilaiStok,
  AvgSales,
  DoiValue,
  StokDate,
}) {
  useEffect(() => {
    setDataProduk([]);
    setSelectedWilayah("");
  }, [dataCabang]);

  //loading
  const [loader, showLoader, hideLoader] = Full();

  //konversi nilai
  const formatToRupiah = (value) => {
    return numeral(value).format("0,0"); // Format angka dengan koma sebagai pemisah ribuan
  };

  const [selectedWilayah, setSelectedWilayah] = useState(""); // State to store selected wilayah

  const handleWilayahChange = (e) => {
    setSelectedWilayah(e.target.value); // Update the selected wilayah
  };

  // Filter data based on the selected wilayah
  const filteredData = selectedWilayah
    ? dataCabang.filter((item) => item.lokasi === selectedWilayah)
    : dataCabang;

  const sortedData = filteredData.sort((a, b) => {
    if (a.ValueStock === null) return -1; // Place null values first
    if (b.ValueStock === null) return 1; // Place other values after null
    return b.ValueStock - a.ValueStock; // Sort by doiValue for non-null values
  });
  const [dataProduk, setDataProduk] = useState([]);
  const [namaCabang, setNamaCabang] = useState("");
  const [sellerId, setSellerId] = useState("");
  const [NilaiStok1, setNilaiStok1] = useState("");
  const [AvgSales1, setAvgSales1] = useState("");
  const [DoiValue1, setDoiValue1] = useState("");

  //ketika pilih unit stok
  const handleProduk = (nameCabang, nextTable, sellerId) => {
    const payload = {
      rank: rank,
      subdist_code: subdistId,
      search_name: nextTable,
      cabang: sellerId,
      product_code: "",
    };
    setNamaCabang(nameCabang);
    setSellerId(sellerId);
    showLoader();
    getDataProduk(payload)
      .then((res) => {
        if ((res.data.success = "true")) {
          setDataProduk(res.data.data);
          setNilaiStok1(res.data.subtotal_totalValue);
          setAvgSales1(res.data.subtotal_avgValue);
          setDoiValue1(res.data.subtotal_doiValue);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  useEffect(() => {
    initializeDataTables();
  }, [filteredData]);

  const initializeDataTables = () => {
    // Initialize DataTables library here after data is fetched and rendered
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/content.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  return (
    <>
      {dataCabang.length > 0 ? (
        <section className="">
          <div style={{ marginTop: "30px" }}>
            <div className="border-[1px] border-[#437998] rounded-xl p-2">
              <div className="flex flex-col lg:flex-row justify-between">
                <div className="w-full lg:w-[550px] lg:my-6 lg:mx-2 my-2">
                  <h3 className="font-semibold text-lg mx-2">{namaCabangs}</h3>
                </div>
                <div className="w-full lg:w-1/3 lg:my-6 lg:mx-2 my-2 border-none border-[#FFE4E4] rounded-xl bg-[#FFE4E4]">
                  <h3 className="text-medium text-center text-[12px] lg:text-[16px] px-2 py-2">
                    Tanggal Data Stok :{" "}
                    {moment(StokDate).format("DD MMMM YYYY", {
                      locale: "id",
                    })}
                  </h3>
                </div>
              </div>

              <div className="">
                <div className="card-body">
                  <table
                    className="table table-bordered table-hover table-scroll"
                    id="example500"
                    style={{
                      fontSize: "12px",
                      fontWeight: "semiBold",
                      display: "block",
                      overflow: "scroll",
                      height: "420px",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#736098",
                        color: "white",
                        textAlign: "center",
                        top: "0",
                        zIndex: "10",
                        position: "sticky",
                        fontWeight: "reguler",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            left: "0",
                            zIndex: "5",
                            position: "sticky",
                            verticalAlign: "middle",
                            backgroundColor: "#736098",
                          }}
                        >
                          NO
                        </th>
                        <th
                          style={{
                            left: "40px",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlign: "middle",
                            backgroundColor: "#736098",
                          }}
                        >
                          <select
                            onChange={handleWilayahChange}
                            className="text-xs bg-gray-50 border border-gray-300 rounded-lg py-2 text-black uppercase"
                            value={selectedWilayah}
                          >
                            <option value="">Semua Wilayah</option>
                            {/* Generate options dynamically based on available data */}
                            {[
                              ...new Set(dataCabang.map((item) => item.lokasi)),
                            ].map((wilayah, index) => (
                              <option key={index} value={wilayah}>
                                {wilayah}
                              </option>
                            ))}
                          </select>
                        </th>

                        <th
                          style={{
                            left: "60px",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlign: "middle",
                            backgroundColor: "#736098",
                          }}
                        >
                          CABANG
                        </th>
                        {/* <th
                          style={{
                            left: "60px",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlign: "middle",
                            backgroundColor: "#736098",
                          }}
                        >
                          UNIT STOK
                        </th>
                        <th
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          UNIT RATA-RATA SALES
                        </th> */}
                        <th
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          NILAI STOK
                        </th>
                        <th
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          NILAI RATA-RATA SALES
                        </th>
                        <th
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          DOI
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "right" }}>
                      {sortedData.map((list, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              textAlign: "center",
                              left: "0px",
                              zIndex: "5",
                              position: "sticky",
                              backgroundColor: "#ffffff",
                            }}
                            width={"1%"}
                          >
                            {index + 1}.
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              left: "40px",
                              zIndex: "5",
                              position: "sticky",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {list.lokasi}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              left: "60px",
                              zIndex: "5",
                              position: "sticky",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {list.Cabang}
                          </td>
                          {/* <td
                            style={{
                              textAlign: "center",
                              left: "60px",
                              zIndex: "5",
                              position: "sticky",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <span
                              className={`${
                                parseFloat(list.UnitStock) < 0
                                  ? "text-red-500"
                                  : "text-[#5077C4] underline cursor-pointer"
                              }`}
                            
                            >
                              {formatToRupiah(list.UnitStock)}
                            </span>
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={`${
                                parseFloat(list.avg) < 0 ? "text-red-500" : ""
                              }`}
                            >
                              {formatToRupiah(list.avg)}
                            </span>
                          </td> */}
                          <td>
                            <span
                              className={`${
                                parseFloat(list.UnitStock) < 0
                                  ? "text-red-500"
                                  : "text-[#5077C4] underline cursor-pointer"
                              }`}
                              onClick={() =>
                                handleProduk(
                                  list.Cabang,
                                  list.next,
                                  list.seller_id
                                )
                              }
                            >
                              {formatToRupiah(list.ValueStock)}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.avgValue) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {formatToRupiah(list.avgValue)}
                            </span>
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={`${
                                parseFloat(list.doiValue) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {list.doiValue !== null ? (
                                <span> {list.doiValue}</span>
                              ) : (
                                <span>&infin;</span>
                              )}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot className="sticky bottom-0 z-10">
                      <tr className="bg-white border border-slate-800 font-bold">
                        <th colSpan={"3"} className="text-center">
                          TOTAL
                        </th>
                        <th className="text-right">
                          {" "}
                          <span
                            className={`${
                              parseFloat(NilaiStok) < 0 ? "text-red-500" : ""
                            }`}
                          >
                            {formatToRupiah(NilaiStok)}
                          </span>
                        </th>
                        <th className="text-right">
                          {" "}
                          <span
                            className={`${
                              parseFloat(AvgSales) < 0 ? "text-red-500" : ""
                            }`}
                          >
                            {formatToRupiah(AvgSales)}
                          </span>
                        </th>
                        <th className="text-center">
                          {" "}
                          <span
                            className={`${
                              parseFloat(DoiValue) < 0 ? "text-red-500" : ""
                            }`}
                          >
                            {DoiValue}
                          </span>
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <StockSubdistProduk
            dataProduk={dataProduk}
            subdistId={subdistId}
            rank={rank}
            namaCabang={namaCabang}
            NilaiStok={NilaiStok1}
            AvgSales={AvgSales1}
            DoiValue={DoiValue1}
            sellerId={sellerId}
          />
        </section>
      ) : null}

      {loader}
    </>
  );
}

export default StockSubdist;
